import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';


class Loading extends Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <div className="d-flex justify-content-center">
                <div className="spinner-grow text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div> 
              <div className="d-flex justify-content-center" style={{marginTop: '40px'}}>
                  <h1><strong>Marge</strong>Monitor</h1>
              </div>
              <div className="d-flex justify-content-center">
                 <small>{this.props.text}</small>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Loading;
