import klant  from './qliktables/dimensions/klant';
import order  from './qliktables/dimensions/order';
import laadlocatie  from './qliktables/dimensions/laadlocatie';
import loslocatie  from './qliktables/dimensions/loslocatie';
import periode  from './qliktables/dimensions/periode';
import product  from './qliktables/dimensions/product';
import zendinggrootte  from './qliktables/dimensions/zendinggrootte';
import afwijkingen  from './qliktables/dimensions/afwijkingen';
import omzet from './qliktables/measures/omzet';
import kosten from './qliktables/measures/kosten';
import aantal from './qliktables/measures/aantal';
import kpi from './qliktables/measures/kpi';
import eigenwagen from './qliktables/measures/eigenwagen';
import uitbesteed from './qliktables/measures/uitbesteed';
import potency from './qliktables/measures/potency';
import km from './qliktables/measures/km';
import uren from './qliktables/measures/uren';
import urenall from './qliktables/measures/urenall';
import vergelijkperiode from './qliktables/measures/vergelijkperiode';
import persize from './qliktables/measures/persize';

export default
  {
    "qInfo": {
      "qId": "",
      "qType": "table"
    },
    //"qExtendsId": "",
    //"qMetaDef": {},
    //"qStateName": "",
    "qHyperCubeDef": {
      "qStateName": "",
      "qDimensions":
        []
      ,
      "qMeasures":
        []
      ,
      "qInterColumnSortOrder": [
      ],
      "qSuppressZero": false,
      "qSuppressMissing": false,
      "qInitialDataFetch": [],
      "qReductionMode": "N",
      "qMode": "S",
      "qPseudoDimPos": -1,
      "qNoOfLeftDims": -1,
      "qAlwaysFullyExpanded": false,
      "qMaxStackedCells": 5000,
      "qPopulateMissing": false,
      "qShowTotalsAbove": false,
      "qIndentMode": false,
      "qCalcCond": {
        "qv": ""
      },
      "qSortbyYValue": 0,
      "qTitle": {
        "qv": ""
      },
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": "='Selecteer dimensies en meetwaarden'"
        }
      },
      "qColumnOrder": [
        0,
        1,
      ],
      "columnOrder": [
        0,
        1,
      ],
      "columnWidths": [
        -1,
        -1,
      ],
      "customErrorMessage": {
        "calcCond": ""
      }
    },
    "showTitles": false,
    "title": "",
    "subtitle": "",
    "footnote": "",
    "showDetails": false,
    "totals": {
      "show": true,
      "position": "noTotals",
      "label": "Totalen"
    },
    "scrolling": {
      "keepFirstColumnInView": false,
      "horizontal": true
    },
    "multiline": {
      "wrapTextInHeaders": true,
      "wrapTextInCells": true
    },
    "visualization": "table",
    "qLayoutExclude": {
      "disabled": {},	
      "quarantine": {}
    },
    "masterVersion": 0.96
  }
  ;
