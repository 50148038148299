import { dimensions as ritdimensions } from '../ritten/qlik/qlik';
import { dimensions as orderdimensions } from '../orders/qlik/klant/klant';
import {dimensions as sim_orderdimensions} from '../orders/qlik/klant/sim';
import {measures} from './qlik/qlik';

export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Order',
        value: 'order',
        children: [
          {
            qlik: orderdimensions.order.ordernumberdisplay,
            value: 'vD_Order.OrderNumberDisplay',
            label: 'Order Nummer'
          }, 
          {
            qlik: sim_orderdimensions.order.department,
            value: 'vD_Order.Department',
            label: 'Afdeling'
          },
          {
            label: 'Klant',
            value: 'Klant',
            children: [
              {
              value: 'vD_Order.Debtor.Name',
              label: "Naam",
              qlik: orderdimensions.debtor.name
              },
              {
              value: 'vD_Order.Customer.Number',
              label: 'Nummer',
              qlik: orderdimensions.debtor.number
              },
              
            ]
          },
          {
            label: 'Laad Locatie',
            value: 'laadlocatie',
            children: [
              {
                qlik: orderdimensions.loadinglocation.id,
                value: 'vD_Order.LoadingLocation.ID',
                label: 'ID'
              },
              {
                qlik: orderdimensions.loadinglocation.name,
                value: 'vD_Order.LoadingLocation.Name',
                label: 'Naam'
              },
              {
                qlik: orderdimensions.loadinglocation.zipcode,
                value: 'vD_Order.LoadingLocation.ZIPcode',
                label: 'Postcode'
              },
              {
                qlik: orderdimensions.loadinglocation.city,
                value: 'vD_Order.LoadingLocation.City',
                label: 'Plaats'
              },
              {
                qlik: orderdimensions.loadinglocation.country,
                value: 'vD_Order.LoadingLocation.Country',
                label: 'Land'
              },
            ]
          },
          {
            label: 'Los Locatie',
            value: 'loslocatie',
            children: [
              {
                qlik: orderdimensions.unloadinglocation.id,
                value: 'vD_Order.UnloadingLocation.ID',
                label: 'ID'
              },
              {
                qlik: orderdimensions.unloadinglocation.name,
                value: 'vD_Order.UnloadingLocation.Name',
                label: 'Naam'
              },
              {
                qlik: orderdimensions.unloadinglocation.zipcode,
                value: 'vD_Order.UnloadingLocation.ZIPcode',
                label: 'Postcode'
              },
              {
                qlik: orderdimensions.unloadinglocation.city,
                value: 'vD_Order.UnloadingLocation.City',
                label: 'Plaats'
              },
              {
                qlik: orderdimensions.unloadinglocation.country,
                value: 'vD_Order.UnloadingLocation.Country',
                label: 'Land'
              },
            ]
          },
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            qlik: ritdimensions.trip.number,
            value: 'vD_Trip.TripNumber',
            label: 'Ritnummer'
          },
          {
            label: 'Chauffeur',
            value: 'driver',
            children: [
              {
                qlik: ritdimensions.driver.name,
                label: 'Naam',
                value: 'vD_Driver.Name'
              },
              {
                qlik: ritdimensions.driver.number, 
                label: 'Nummer',
                value: 'vD_Driver.Number'
              }
            ]
          },
          {
            label: 'Auto',
            value: 'vehicle',
            children: [
              {
                qlik: ritdimensions.vehicle.number,
                label: 'Nummer',
                value: 'vD_Vehicle.Number'
              },
              {
                qlik: ritdimensions.vehicle.license,
                label: 'Kenteken',
                value: 'vD_Vehicle.License'
              }
            ]
          },
          {
            label: 'Crediteur',
            value: 'crediteur',
            children: [
              {
                qlik: ritdimensions.charter.name,
                label: 'Naam',
                value: 'vD_Trip.Creditor.Name'
              },
              {
                qlik: ritdimensions.charter.number,
                label: 'Nummer',
                value: 'vD_Trip.Creditor.Number'
              }
            ]
          },
          {
            label: 'Trailer',
            value: 'trailer',
            children: [
              {
                qlik: ritdimensions.trailer.number, 
                label: 'Nummer',
                value: 'vD_Trip.Trailer.Number'
              },
            ]
          },

        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            qlik: orderdimensions.period.year,
            value: 'vD_Year',
            label: 'Jaar'
          },
          {
            qlik: orderdimensions.period.quarter,
            value: 'vD_Quarter',
            label: 'Kwartaal'
          },
          {
            qlik: orderdimensions.period.month,
            value: 'vD_Month',
            label: 'Maand'
          },
          {
            qlik: orderdimensions.period.week,
            value: 'vD_Week',
            label: 'Week'
          },
          {
            qlik: orderdimensions.period.yearweek,
            value: 'vD_YearWeek',
            label: 'Jaar-Week'
          },
          {
            qlik: orderdimensions.period.date,
            value: 'vD_Date',
            label: 'Datum'
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            qlik: measures.omzet.totaal,
            value: 'vE_OrderTripOmzet',
            label: 'Totaal'
          },
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            qlik: measures.kosten.totaal,
            value: 'vE_OrderTripKosten',
            label: 'Totaal',
            help: 'Totaal van de directe kosten.'
          },
          {
            qlik: measures.kosten.charter,
            value: 'vE_OrderTripKostenCharter',
            label: 'Derden',
            help: 'Kosten van zowel Italië charters (Excel) en expeditie (Winsped).'
          },
          {
            qlik: measures.kosten.rail,
            value: 'vE_OrderTripKostenRailFerry',
            label: 'Trein',
            help: 'Voorgecalculeerde kosten van treintrajecten uit de tarieventabel van Winsped'
          },
          {
            qlik: measures.kosten.toll,
            value: 'vE_OrderTripKostenTol',
            label: 'Tol',
            help:'Kosten van tol uit Winsped.'
          },
          {
            qlik: measures.kosten.cleaning,
            value: 'vE_OrderTripKostenCleaning',
            label: 'Cleaning',
            help:'Cleaning kosten uit Winsped, mochten die er niet zijn en er wel een cleaning gepland is wordt er met een standaard tarief gerekend.'
          },
          {
            qlik: measures.kosten.warehouse,
            value: 'vE_OrderTripKostenWarehouse',
            label: 'Containerkosten',
            help: 'Voorgecalculeerde containerkosten die berekend worden door een tarief per dag te vermenigvuldigen met de dagen die een container in gebruik is. De container wordt gekoppeld aan een order na de laadactie van de order. De kosten lopen door op de order zo lang er geen nieuwe order geladen is. Eventuele hoge containerkosten op order niveau kan er op wijzen dat de container langere tijd niet geladen is.'
          },
          {
            qlik: measures.kosten.other,
            value: 'vE_OrderTripKostenOther',
            label: 'Overig',
            help: 'Overige kosten uit Winsped'
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            qlik: measures.aantal.orders,
            value: 'vE_OrderTripAantalOrders',
            label: 'Zendingen',
          },
          {
            qlik: measures.aantal.ritten,
            value: 'vE_OrderTripAantalRitten',
            label: 'Ritten',
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            qlik: measures.kpi.goedkeur,
            value: 'vE_KPI_OrderGoedkeur',
            label: 'OK'
          },

          {
            qlik: measures.kpi.normmarginpercent,
            value: 'vE_KPI_OrderNormMarginPercent',
            label: 'Norm Marge %'
          },
          {
            qlik: measures.kpi.normmarginomzet,
            value: 'vE_KPI_OrderNormMarginOverOmzet',
            label: 'Norm Marge'
          },


        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            qlik: measures.own.omzetperuur,
            value: 'vE_KPI_OrderOmzetPerUur',
            label: 'Omzet / Uur'
          },
          {
            qlik: measures.own.bezetting,
            value: 'vE_KPI_OrderBezetting',
            label: 'Bezetting'
          },
          {
            qlik: measures.own.normomzetperuur,
            value: 'vE_OrderNormTurnoverHour',
            label: 'Norm Omzet / Uur'
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            qlik: measures.outsourced.percentage,
            value: 'vE_KPI_OrderTripOutsourced',
            label: '% Uitbesteed'
          },
          {
            qlik: measures.outsourced.marge,
            value: 'vE_KPI_OrderCharterMarge',
            label: 'Derden Marge'
          },
          {
            qlik: measures.outsourced.norm,
            value: 'vE_OrderNormCharter',
            label: 'Norm'
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            qlik: measures.km.km,
            value: 'vE_OrderKM',
            label: 'Totaal'
          },
          {
            qlik: measures.km.kmfull,
            value: 'vE_OrderKMFull',
            label: 'Beladen'
          },
          {
            qlik: measures.km.kmempty,
            value: 'vE_OrderKMEmpty',
            label: 'Onbeladen'
          },
          {
            qlik: measures.km.omzetperkm,
            value: 'vE_KPI_OrderOmzetPerKM',
            label: 'Omzet / KM'
          },
          {
            qlik: measures.km.omzetperkmfull,
            value: 'vE_KPI_OrderOmzetPerKMFull',
            label: 'Omzet / beladen KM'
          },
          {
            qlik: measures.km.belading,
            value: 'vE_KPI_OrderBelading',
            label: '% Beladen'
          },
          {
            qlik: measures.km.normomzetperkm,
            value: 'vE_OrderNormTurnoverKM',
            label: 'Norm Omzet / KM'
          },
          {
            qlik: measures.km.normomzetperkmfull,
            value: 'vE_OrderNormTurnoverKMFull',
            label: 'Norm Omzet / beladen KM'
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            qlik: measures.uren.totaal,
            value: 'vE_OrderTripUren',
            label: 'Totaal (Σ)',
          },
          {
            qlik: measures.uren.loading,
            value: 'vE_OrderTripUrenLoading',
            label: 'Laden (Σ)',
          },
          {
            qlik: measures.uren.unloading,
            value: 'vE_OrderTripUrenUnloading',
            label: 'Lossen (Σ)',
          },
          {
            qlik: measures.uren.driving,
            value: 'vE_OrderTripUrenDriving',
            label: 'Rijden (Σ)',
          },
          {
            qlik: measures.uren.other,
            value: 'vE_OrderTripUrenOther',
            label: 'Overig (Σ)',
          },
          {
            qlik: measures.uren.cleaning,
            value: 'vE_OrderTripUrenCleaning',
            label: 'Spoelen (Σ)',
          },
          {
            qlik: measures.uren.avg_totaal,
            value: 'vE_OrderTripUrenAvg',
            label: 'Totaal (avg)',
          },
          {
            qlik: measures.uren.avg_loading,
            value: 'vE_OrderTripUrenAvgLoading',
            label: 'Laden (avg)',
          },
          {
            qlik: measures.uren.avg_unloading,
            value: 'vE_OrderTripUrenAvgUnloading',
            label: 'Lossen (avg)',
          },
          {
            qlik: measures.uren.avg_driving,
            value: 'vE_OrderTripUrenAvgDriving',
            label: 'Rijden (avg)',
          },
          {
            qlik: measures.uren.avg_other,
            value: 'vE_OrderTripUrenAvgOther',
            label: 'Overig (avg)',      // $(wActive(Order, 'M_Uren_Rijden_A'))
          },
          {
            qlik: measures.uren.avg_cleaning,
            value: 'vE_OrderTripUrenAvgCleaning',
            label: 'Spoelen (avg)',
          },
        ]
      },
    ]
  }
];
