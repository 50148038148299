import OptionsWIJ from './options/OptionsWIJ';


// Clean hostname (www. & dev.)
var hostname = window.location.hostname.replace(/^(www\.)/,"").replace(/^(dev\.)/,"");
var optionMapping = [
    {hostname: 'abtexel.margemonitor.nl', options: 'OptionsABT'},
    {hostname: 'boonstra.margemonitor.nl', options: 'OptionsBOO'},
    {hostname: 'bosdynamics.margemonitor.nl', options: 'OptionsBOS'},
    {hostname: 'brinks.margemonitor.nl', options: 'OptionsBRI'},
    {hostname: 'curfs.margemonitor.nl', options: 'OptionsCUR'},
    {hostname: 'demo.margemonitor.nl', options: 'OptionsDEMO'},
    {hostname: 'electroniclogistics.margemonitor.nl', options: 'OptionsELO'},
    {hostname: 'sandersfritom.margemonitor.nl', options: 'OptionsFRSD'}, 
    {hostname: 'veenstrafritom.margemonitor.nl', options: 'OptionsFRVD'}, 
    {hostname: 'hoekstra.margemonitor.nl', options: 'OptionsHOE'},
    {hostname: 'jankrediet.margemonitor.nl', options: 'OptionsJKS'},
    {hostname: 'lubbers.margemonitor.nl', options: 'OptionsLUB'},
    {hostname: 'gesimons.margemonitor.nl', options: 'OptionsSIM'},
    {hostname: 'vredeveld.margemonitor.nl', options: 'OptionsVRE'},
    {hostname: 'evanwijk.margemonitor.nl', options: 'OptionsWIJ'},
    {hostname: 'wessels.margemonitor.nl', options: 'OptionsWTR'},

    {hostname: 'azure.margemonitor.nl', options: 'OptionsFRVD'},
    {hostname: 'localhost', options: 'OptionsWIJ'}
]; 

var matches = optionMapping.filter(x => x.hostname === hostname);
console.log('HOSTNAME:',hostname);
var options = null; 
console.log(matches);  
if (matches[0]){
    options = require('./options/' + matches[0].options).default;
}


export default options; 