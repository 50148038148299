import order  from './qliktables/dimensions/order';
import order_laadlocatie from './qliktables/dimensions/order_laadlocatie';
import order_loslocatie from './qliktables/dimensions/order_loslocatie';
import order_klant from './qliktables/dimensions/order_klant';

import rit  from './qliktables/dimensions/rit';
import periode  from './qliktables/dimensions/periode';
import trip_trailer  from './qliktables/dimensions/trip_trailer';
import trip_vehicle  from './qliktables/dimensions/trip_vehicle';
import trip_crediteur  from './qliktables/dimensions/trip_crediteur';
import trip_eindlocatie from './qliktables/dimensions/trip_eindlocatie';
import trip_startlocatie from './qliktables/dimensions/trip_startlocatie';
import trip_driver from './qliktables/dimensions/trip_driver';
import omzet from './qliktables/measures/omzet';
import kosten from './qliktables/measures/kosten';
import aantal from './qliktables/measures/aantal';
import kpi from './qliktables/measures/kpi';
import eigenwagen from './qliktables/measures/eigenwagen';
import uitbesteed from './qliktables/measures/uitbesteed';
import km from './qliktables/measures/km';
import uren from './qliktables/measures/uren';
import persize from './qliktables/measures/persize';

export default
  {
    "qInfo": {
      "qId": "",
      "qType": "table"
    },
    "qExtendsId": "",
    "qMetaDef": {},
    "qStateName": "",
    "qHyperCubeDef": {
      "qStateName": "",
      "qDimensions":
        order.qHyperCubeDef.qDimensions
          .concat(order_klant.qHyperCubeDef.qDimensions)
          .concat(order_laadlocatie.qHyperCubeDef.qDimensions)
          .concat(order_loslocatie.qHyperCubeDef.qDimensions)
          .concat(rit.qHyperCubeDef.qDimensions)
          .concat(trip_trailer.qHyperCubeDef.qDimensions)
          .concat(trip_vehicle.qHyperCubeDef.qDimensions)
          .concat(trip_crediteur.qHyperCubeDef.qDimensions)
          .concat(trip_driver.qHyperCubeDef.qDimensions)
          .concat(trip_eindlocatie.qHyperCubeDef.qDimensions)
          .concat(trip_startlocatie.qHyperCubeDef.qDimensions)
          .concat(periode.qHyperCubeDef.qDimensions)
      ,
      "qMeasures":
        omzet.qHyperCubeDef.qMeasures
          .concat(kosten.qHyperCubeDef.qMeasures)
          .concat(aantal.qHyperCubeDef.qMeasures)
          .concat(kpi.qHyperCubeDef.qMeasures)
          .concat(eigenwagen.qHyperCubeDef.qMeasures)
          .concat(uitbesteed.qHyperCubeDef.qMeasures)
          //.concat(potency.qHyperCubeDef.qMeasures)
          .concat(km.qHyperCubeDef.qMeasures)
          .concat(uren.qHyperCubeDef.qMeasures)
          //.concat(vergelijkperiode.qHyperCubeDef.qMeasures)
          .concat(persize.qHyperCubeDef.qMeasures)
      ,
      "qInterColumnSortOrder": [
        0,
        1,
      ],
      "qSuppressZero": false,
      "qSuppressMissing": false,
      "qInitialDataFetch": [],
      "qReductionMode": "N",
      "qMode": "S",
      "qPseudoDimPos": -1,
      "qNoOfLeftDims": -1,
      "qAlwaysFullyExpanded": false,
      "qMaxStackedCells": 5000,
      "qPopulateMissing": false,
      "qShowTotalsAbove": false,
      "qIndentMode": false,
      "qCalcCond": {
        "qv": "len('$(wSelectionTripTable)')>0"
      },
      "qSortbyYValue": 0,
      "qTitle": {
        "qv": ""
      },
      "qCalcCondition": {
        "qCond": {
          "qv": "len('$(wSelectionTripTable)')>0"
        },
        "qMsg": {
          "qv": "='Selecteer dimensies en meetwaarden'"
        }
      }
    },
    "showTitles": false,
    "title": "",
    "subtitle": "",
    "footnote": "",
    "showDetails": false,
    "totals": {
      "show": true,
      "position": "noTotals",
      "label": "Totalen"
    },
    "scrolling": {
      "keepFirstColumnInView": false,
      "horizontal": true
    },
    "multiline": {
      "wrapTextInHeaders": true,
      "wrapTextInCells": true
    },
    "visualization": "table",
    "masterVersion": 0.96
  }
  ;