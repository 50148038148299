import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import bootstrap from './axios';
import useBackend from './use-backend';
import Options from './settings/Options';
import Loading from './views/Pages/Loading/Loading';

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
console.log("ReactLocation.Search", window.location.href.split('?')[1]);

bootstrap(`https://${Options.config.host}`, Options.config.webIntegrationId);
const App = () => {
  let view; 
  let userUrl = null; 
  if (Options.connections.qlikCloud){
    userUrl = '/v1/users/me';
  }else {
    let searchstring = window.location.href.split('?')[1];
    if(searchstring){
      userUrl = '/resources/img/core/dark_noise_16x16.png?'   + searchstring;
    }
    else{
      userUrl =  '/resources/img/core/dark_noise_16x16.png';
    }
    
  }
  const [user, userError, userIsLoading, response] = useBackend({ url: userUrl });  
  const needsLogin = ((userError && userError.response && userError.response.status === 401)) || (!userIsLoading && user && user.includes("<title>Qlik Sense login page</title>"));
  if (userIsLoading) {
    view = (<Loading text='Verbinding met QlikSense controleren...'/>);
  } else if (needsLogin) {
    view = (  
    <HashRouter>
      <React.Suspense fallback={<Loading/>}>
          <Switch>
            <Route path="/" name="Home" render={props => <Login {...props}/>} />
          </Switch>
      </React.Suspense>
    </HashRouter>
    );
  } else if (userError && Options.connections.qlikCloud) {
    view = (<div>Unable to fetch user, likely misconfigured tenant/web integration. <pre><code>{userError.stack}</code></pre></div>);
  } else if(!user) {
    view = (<Loading text='Kan geen verbinding maken met QlikSense. Is de QlikSense server bereikbaar vanaf uw huidige locatie?'/>);
  } else {
    const Page404 = React.lazy(() => import('./views/Pages/Page404'));
    const Page500 = React.lazy(() => import('./views/Pages/Page500'));
    const Development = React.lazy(() => import('./views/Development/Development'));
    const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

    view = (
      <HashRouter>
          <React.Suspense fallback={<Loading/>}>
              <Switch>
                <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                <Route exact path="/Development" name="Development" render={props => <Development {...props}/>} />
                <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
              </Switch>
          </React.Suspense>
      </HashRouter>
    );
  } 
  return (<div>{view}</div>)
}

export default App;
