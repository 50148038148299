import qlik from './qlik';
import {measures} from './qlik/qlik';
import { dimensions as ritdimensions } from '../ritten/qlik/qlik';
import { dimensions as orderdimensions } from '../orders/qlik/klant/klant';

export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Order',
        value: 'order',
        children: [
          {
            new_value: 'orderdimensions.order.ordernumberdisplay',
            value: 'vD_Order.OrderNumberDisplay',
            label: 'Zending',
            qlik: orderdimensions.order.ordernumberdisplay
          }, 
          {
            label: 'Opdrachtgever',
            value: 'Opdrachtgever',
            children: [
              {
              new_value: "orderdimensions.customer.name",
              value: 'vD_Order.Customer.Name',
              label: "Opdrachtgever - Naam",
              qlik: orderdimensions.customer.name
              },
              {
              new_value: "orderdimensions.customer.number",
              value: 'vD_Order.Customer.Number',
              label: 'Opdrachtgever - Nummer',
              qlik: orderdimensions.customer.number
              },
              {
              new_value: "orderdimensions.customer.type",
              value: 'vD_Order.Customer.Type',
              label: 'Type',
              qlik: orderdimensions.customer.type
              },
              
            ]
          },
          {
            label: 'Laad Locatie',
            value: 'laadlocatie',
            children: [
              {
                new_value: 'orderdimensions.loadinglocation.zipcode',
                value: 'vD_Order.LoadingLocation.ZIPcode',
                label: 'Postcode',
                qlik: orderdimensions.loadinglocation.zipcode
              },
              {
                new_value: 'orderdimensions.loadinglocation.city',
                value: 'vD_Order.LoadingLocation.City',
                label: 'Plaats',
                qlik: orderdimensions.loadinglocation.city
              },
              {
                new_value: 'orderdimensions.loadinglocation.district',
                value: 'vD_Order.LoadingLocation.District',
                label: 'Gemeente',
                qlik: orderdimensions.loadinglocation.district
              },
              {
                new_value: 'orderdimensions.loadinglocation.province',
                value: 'vD_Order.LoadingLocation.Province',
                label: 'Provincie',
                qlik: orderdimensions.loadinglocation.province
              },
              {
                new_value: 'orderdimensions.loadinglocation.country',
                value: 'vD_Order.LoadingLocation.Country',
                label: 'Land',
                qlik: orderdimensions.loadinglocation.country
              },
              {
                new_value: 'orderdimensions.loadinglocation.land',
                value: 'vD_Order.LoadingLocation.Land',
                label: 'Land (ISO)',
                qlik: orderdimensions.loadinglocation.land
              }
            ]
          },
          {
            label: 'Los Locatie',
            value: 'loslocatie',
            children: [
              {
                new_value: 'orderdimensions.unloadinglocation.zipcode',
                value: 'vD_Order.UnloadingLocation.ZIPcode',
                label: 'Postcode',
                qlik: orderdimensions.unloadinglocation.zipcode
              },
              {
                new_value: 'orderdimensions.unloadinglocation.city',
                value: 'vD_Order.UnloadingLocation.City',
                label: 'Plaats',
                qlik: orderdimensions.unloadinglocation.city
              },
              {
                new_value: 'orderdimensions.unloadinglocation.district',
                value: 'vD_Order.UnloadingLocation.District',
                label: 'Gemeente',
                qlik: orderdimensions.unloadinglocation.district
              },
              {
                new_value: 'orderdimensions.unloadinglocation.province',
                value: 'vD_Order.UnloadingLocation.Province',
                label: 'Provincie',
                qlik: orderdimensions.unloadinglocation.province
              },
              {
                new_value: 'orderdimensions.unloadinglocation.country',
                value: 'vD_Order.UnloadingLocation.Country',
                label: 'Land',
                qlik: orderdimensions.unloadinglocation.country
              },
              {
                new_value: 'orderdimensions.unloadinglocation.land',
                value: 'vD_Order.UnloadingLocation.Land',
                label: 'Land (ISO)',
                qlik: orderdimensions.unloadinglocation.land
              }
            ]
          },
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            new_value: 'ritdimensions.trip.number',
            value: 'vD_Trip.TripNumber',
            label: 'Rit Nummer',
            qlik: ritdimensions.trip.number
          },
          {
            label: 'Chauffeur',
            value: 'driver',
            children: [
              {
                label: 'Naam',
                new_value: 'ritdimensions.driver.name',
                value: 'vD_Driver.Name',
                qlik: ritdimensions.driver.name
              },
              {
                label: 'Nummer',
                new_value: 'ritdimensions.driver.number',
                value: 'vD_Driver.Number',
                qlik: ritdimensions.driver.number
              }
            ]
          },
          {
            label: 'Auto',
            value: 'vehicle',
            children: [
              {
                label: 'Naam',
                new_value: 'ritdimensions.vehicle.name',
                value: 'vD_Vehicle.Name',
                qlik: ritdimensions.vehicle.name
              },
              {
                label: 'Nummer',
                new_value: 'ritdimensions.vehicle.number',
                value: 'vD_Vehicle.Number',
                qlik: ritdimensions.vehicle.number
              }
            ]
          },
          {
            label: 'Crediteur',
            value: 'crediteur',
            children: [
              {
                label: 'Naam',
                new_value: 'ritdimensions.charter.name',
                value: 'vD_Trip.CreditorName',
                qlik: ritdimensions.charter.name
              },
              {
                label: 'Nummer',
                new_value: 'ritdimensions.charter.number',
                value: 'vD_Trip.CreditorNumber',
                qlik: ritdimensions.charter.number
              },
              {
                label: 'Zoekcode', 
                help: 'Crediteurzoekcode uit Transpas',
                new_value: 'ritdimensions.charter.searchcode',
                value: 'vD_Trip.Creditor.SearchCode',
                qlik: ritdimensions.charter.searchcode
              },
              {
                label: 'Soort', 
                help: 'Chartertype uit Transpas',
                new_value: 'ritdimensions.charter.type',
                value: 'vD_Trip.Creditor.Kind.Description',
                qlik: ritdimensions.charter.kinddescription
              },
              {
                label: 'Type', 
                help: 'Chartertype uit Transpas',
                new_value: 'ritdimensions.charter.type',
                value: 'vD_Trip.Creditor.Type',
                qlik: ritdimensions.charter.type
              } 
            ]
          },
          {
            label: 'Trailer',
            value: 'trailer',
            children: [
              {
                label: 'Nummer',
                new_value: 'ritdimensions.trailer.number',
                value: 'vD_Trip.Trailer.Number',
                qlik: ritdimensions.trailer.number
              },
            ]
          },

        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            new_value: 'ritdimensions.period.year',
            value: 'vD_Year',
            label: 'Jaar',
            qlik: ritdimensions.period.year
          },
          {
            new_value: 'ritdimensions.period.quarter',
            value: 'vD_Quarter',
            label: 'Kwartaal',
            qlik: ritdimensions.period.quarter
          },
          {
            new_value: 'ritdimensions.period.month',
            value: 'vD_Month',
            label: 'Maand',
            qlik: ritdimensions.period.month
          },
          {
            new_value: 'ritdimensions.period.week',
            value: 'vD_Week',
            label: 'Week',
            qlik: ritdimensions.period.week
          },
          {
            new_value: 'ritdimensions.period.date',
            value: 'vD_Date',
            label: 'Datum',
            qlik: ritdimensions.period.date
          },
          {
            new_value: 'ritdimensions.period.weekday',
            value: 'vD_Weekday',
            label: 'Dag',
            qlik: ritdimensions.period.weekday
          }
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            new_value: 'measures.omzet.totaal',
            value: 'vE_OrderTripOmzet',
            label: 'Totaal',
            qlik: measures.omzet.totaal
          },
          {
            new_value: 'measures.omzet.freight',
            value: 'vE_OrderTripOmzet.Freight',
            label: 'Vracht',
            help: 'Vrachtkosten vanuit Transpas',
            qlik: measures.omzet.freight
          },
          {
            new_value: 'measures.omzet.fuel',
            value: 'vE_OrderTripOmzet.Fuel',
            label: 'Brandstof',
            help: 'Brandstoftoeslagen zoals deze berekend worden op zendingniveau in Transpas',
            qlik: measures.omzet.fuel
          },
          {
            new_value: 'measures.omzet.extra',
            value: 'vE_OrderTripOmzet.ExtraKosten',
            label: 'Extra Kosten',
            qlik: measures.omzet.extra,
            help: 'Opbrengsten beschikbaar in Transpas onder het menuitem \'Extra Kosten\' '
          }
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            new_value: 'measures.kosten.totaal',
            value: 'vE_OrderTripKosten',
            label: 'Totaal',
            qlik: measures.kosten.totaal,
            help: 'Totaal van de directe kosten.'
          },
          {
            new_value: 'measures.kosten.charter',
            value: 'vE_OrderTripKostenCharter',
            label: 'Vracht', 
            qlik: measures.kosten.charter,
            help: 'Kosten van kilometer, uren en vaste charters'
          },
          {
            new_value: 'measures.kosten.toll',
            value: 'vE_OrderTripKostenTol',
            label: 'Tol',
            qlik: measures.kosten.toll,
            help: 'Tolkosten worden enkel vanuit Transpas meegenomen wanneer ze geregistreerd worden op een eigen auto'
          },
          {
            new_value: 'measures.kosten.warehouse',
            value: 'vE_OrderTripKostenWarehouse',
            label: 'Crossdock',
            qlik: measures.kosten.warehouse,
            help: 'Voorcalculatorische kosten vanuit Transpas voor de reservering van de directe Warehouse kosten per zending'
          },
          {
            new_value: 'measures.kosten.other',
            value: 'vE_OrderTripKostenOther',
            label: 'Other',
            qlik: measures.kosten.other,
            help: 'Alle kosten die niet in één van bovenstaande categoriën ingedeeld zijn vallen hieronder'
          }
    
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            new_value: 'measures.aantal.dossiers',
            value: 'vE_OrderTripAantalDossiers',
            label: 'Dossiers',
            qlik: measures.aantal.dossiers,
            help: 'Het aantal dossiers, waarbij meerdere zendingen gecombineerd worden'
          },
          {
            new_value: 'measures.aantal.orders',
            value: 'vE_OrderTripAantalOrders',
            label: 'Zendingen',
            qlik: measures.aantal.orders,
            help: 'Het aantal zendingen, waarbij een zending een unieke transportopdracht is van A naar B'
          },
          {
            new_value: 'measures.aantal.ritten',
            value: 'vE_OrderTripAantalRitten',
            label: 'Ritten',
            qlik: measures.aantal.ritten,
            help: 'Het aantal ritten behorend bij de geselecteerde zendingen. Hier zien we altijd het totaal. '
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            new_value: 'measures.kpi.goedkeur',
            value: 'vE_KPI_OrderTripGoedkeur',
            label: 'OK',
            qlik: measures.kpi.goedkeur
          },
          {
            new_value: 'measures.kpi.normmarginpercent',
            value: 'vE_KPI_OrderTripNormMarginPercent',
            label: 'Norm Marge %',
            qlik: measures.kpi.normmarginpercent
          },
          {
            new_value: 'measures.kpi.normmarginomzet',
            value: 'vE_KPI_OrderTripNormMarginOverOmzet',
            label: 'Norm Marge',
            qlik: measures.kpi.normmarginomzet
          },
        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            new_value: 'measures.own.omzetperuur',
            value: 'vE_KPI_OrderTripOmzetPerUur',
            label: 'Omzet / Uur',
            qlik: measures.own.omzetperuur
          },
          {
            new_value: 'measures.own.normomzetperuur',
            value: 'vE_OrderTripNormTurnoverHour',
            label: 'Norm Omzet / Uur',
            qlik: measures.own.normomzetperuur
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            new_value: 'measures.outsourced.percentage',
            value: 'vE_KPI_OrderTripOutsourced',
            label: '% Uitbesteed',
            qlik: measures.outsourced.percentage
          },
          {
            new_value: 'measures.outsourced.marge',
            value: 'vE_KPI_OrderTripCharterMarge',
            label: 'Derden Marge',
            qlik: measures.outsourced.marge
          },
          {
            new_value: 'measures.outsourced.norm',
            value: 'vE_OrderTripNormCharter',
            label: 'Norm',
            qlik: measures.outsourced.norm
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            new_value: 'measures.km.km',
            value: 'vE_OrderTripKM',
            label: 'Totaal',
            qlik: measures.km.km
          },
          {
            new_value: 'measures.km.omzetperkm',
            value: 'vE_KPI_OrderTripOmzetPerKM',
            label: 'Omzet / KM',
            qlik: measures.km.omzetperkm
          },
          {
            new_value: 'measures.km.normomzetperkm',
            value: 'vE_OrderTripNormTurnoverKM',
            label: 'Norm Omzet / KM',
            qlik: measures.km.normomzetperkm
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            new_value: 'measures.uren.totaal',
            value: 'vE_OrderTripUren',
            label: 'Totaal (Σ)',
            qlik: measures.uren.totaal
          },
          {
            new_value: 'measures.uren.loading',
            value: 'vE_OrderTripUrenLoading',
            label: 'Laden (Σ)',
            qlik: measures.uren.loading
          },
          {
            new_value: 'measures.uren.unloading',
            value: 'vE_OrderTripUrenUnloading',
            label: 'Lossen (Σ)',
            qlik: measures.uren.unloading
          },
          {
            new_value: 'measures.uren.driving',
            value: 'vE_OrderTripUrenDriving',
            label: 'Rijden (Σ)',
            qlik: measures.uren.driving
          },
          {
            new_value: 'measures.uren.other',
            value: 'vE_OrderTripUrenOther',
            label: 'Overig (Σ)',
            qlik: measures.uren.other
          },
          {
            new_value: 'measures.uren.avg_totaal',
            value: 'vE_OrderTripUrenAvg',
            label: 'Totaal (avg)',
            qlik: measures.uren.avg_totaal
          },
          {
            new_value: 'measures.uren.avg_loading',
            value: 'vE_OrderTripUrenAvgLoading',
            label: 'Laden (avg)',
            qlik: measures.uren.avg_loading
          },
          {
            new_value: 'measures.uren.avg_unloading',
            value: 'vE_OrderTripUrenAvgUnloading',
            label: 'Lossen (avg)',
            qlik: measures.uren.avg_unloading
          },
          {
            new_value: 'measures.uren.avg_driving',
            value: 'vE_OrderTripUrenAvgDriving',
            label: 'Rijden (avg)',
            qlik: measures.uren.avg_driving
          },
          {
            new_value: 'measures.uren.avg_other',
            value: 'vE_OrderTripUrenAvgOther',
            label: 'Overig (avg)', 
            qlik: measures.uren.avg_other 
          },
        ]
      },
    ]
  }
];
