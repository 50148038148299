import React from 'react';

import orderList from '../../margemonitor/components/orders/dimensions-FRV';
import ritList from '../../margemonitor/components/ritten/dimensions-FRV';
import orderTripList from '../../margemonitor/components/trajecten/dimensions-FRV';

const KM_aansluiting = React.lazy(() => import('./../../views/Maatwerk/WIJ/KM-aansluiting'));
const Uren_aansluiting = React.lazy(() => import('./../../views/Maatwerk/WIJ/ControleUren'));
const NarekenenKPI = React.lazy(() => import('./../../views/Maatwerk/FRV/NarekenenKPI'));



const options = {
  dimensions: {
    order: orderList,
    rit: ritList,
    traject: orderTripList,
  },
  config: {
    //host: 'localhost',
    host: window.location.hostname, 
    secure: true,
    //port: '',
    prefix: '',
    appId: '538cbd91-1a55-4e09-ad78-cf0dceae31fa'
    //appId: '81e57a41-89b4-4ef4-97fd-b2ba30c07134'
  },
  connections: {
    vizApi: true,
    engineApi: true,
    useUniqueSessionID: 'MargeMonitor'
  },
  filters: {
    calendar: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Year)'],
            placeholder: 'Jaar',
            expanded: true, 
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Quarter)'],
            placeholder: 'Kwartaal',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Month)'],
            placeholder: 'Maand',
            expandedHorizontal: true,
            expanded: true, 
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Week)'],
            placeholder: 'Week',
            autoSortByState: 0,
            qSortByAscii: 1,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Weekday)]'],
            placeholder: 'Dag',
            expandedHorizontal: true,
            expanded: true, 
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0,
          }
        },   
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Date)'],
            placeholder: 'Datum',
          },
        },
               
      ],
    },
    location: {
      replace: false,
      filters: [
        {
          type: 'title',
          title: 'Order Laadlocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Name)]'],
            placeholder: 'Naam',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Adress)]'],
            placeholder: 'Adres',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.SearchCode)]'],
            placeholder: 'Zoekcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.District)]'],
            placeholder: 'Gemeente',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Province)]'],
            placeholder: 'Provincie',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Land)]'],
            placeholder: 'Land (ISO)',
          },
        },
        {
          type: 'title',
          title: 'Order Loslocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Name)]'],
            placeholder: 'Naam',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Adress)]'],
            placeholder: 'Adres',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.SearchCode)]'],
            placeholder: 'Zoekcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.District)]'],
            placeholder: 'Gemeente',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Province)]'],
            placeholder: 'Provincie',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Land)]'],
            placeholder: 'Land (ISO)',
          },
        },
      ],
    },order: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.OrderNumberDisplay)]'],
            placeholder: 'Zending',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Dossier)]'],
            placeholder: 'Dossier',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'title',
          title: 'Kenmerken'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.PlanGroup)]'],
            placeholder: 'Plangroep',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LandGroup)]'],
            placeholder: 'Planafdeling',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Reference)]'],
            placeholder: 'Referentie',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Status)]'],
            placeholder: 'Status',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Kind)]'],
            placeholder: 'Soort',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Zendinggrootte)]'],
            placeholder: 'Zendinggrootte',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Service)]'],
            placeholder: 'Service',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Filter.Type)]'],
            placeholder: 'Filtertype',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'title',
          title: 'Opdrachtgever'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Order.Customer.Name)'],
            placeholder: 'Naam',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Customer.Number)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Customer.Type)]'],
            placeholder: 'Type',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: 'Debiteur'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Name)]'],
            placeholder: 'Naam',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Number)]'],
            placeholder: 'Number',
            showStateInDropdown: true,
          },
        }
      ],
    },
    trip: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.TripNumber)]'],
            placeholder: 'Rit nummer',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Kenmerken" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.ResourceCombination)]'],
            placeholder: 'Resource Combination',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.PlanGroup)]'],
            placeholder: 'Plangroep',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.LandGroup)]'],
            placeholder: 'Planafdeling',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Auto" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Number)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Name)]'],
            placeholder: 'Naam',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Vehicle.Type)]'],
            placeholder: 'Type',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Chauffeur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Number)]'],
            placeholder: 'Chauffeur nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Name)]'],
            placeholder: 'Chauffeur naam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Crediteur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Number)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Name)]'],
            placeholder: 'Naam',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.SearchCode)]'],
            placeholder: 'Zoekcode',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Kind.Description)]'],
            placeholder: 'Soort',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Type)]'],
            placeholder: 'Type',
            showStateInDropdown: true,
          },
        },
        
      ],
    },
  },
  menu: {
    disable: [],
    menu: [
        {
          title: true,
          name: '',
          wrapper: {
            element: '',
            attributes: {},
          },
        },
        {
          title: true,
          name: 'Maatwerk',
          wrapper: {
            element: '',
            attributes: {},
          },
        },
        {
          name: 'KM aansluiting',
          url: '/frv/km-aansluiting',
          icon: 'fa fa-check',
        },
        {
          name: 'Uren aansluiting',
          url: '/frv/uren-aansluiting',
          icon: 'fa fa-clock',
        },
        {
          name: 'Narekenen KPI',
          url: '/frv/narekenen-kpi',
          icon: 'fa fa-calculator'
        }
        // {
        //   name: 'Order',
        //   url: '/wij/controle',
        //   icon: 'fa fa-check',
        //   children: [
        //     {
        //       name: 'Dashboard'
        //     },
        //     {
        //       name: 'Uren',
        //       icon: 'fa fa-check',
        //       url: '/wij/controle/km'
        //     },
        //     {
        //       name: 'Uren',
        //       icon: 'fa fa-check',
        //       url: '/wij/controle/km'
        //     },
        //     {
        //       name: 'Detail',
        //       icon: 'fa fa-check',
        //       url: '/wij/controle/km'
        //     },
        //     {
        //       name: 'Self Service',
        //       url: '/wij/controle/uren'
        //     }
        //   ]
        // },
      ],
  }, 
  routes: [
    { path: '/frv/km-aansluiting', name: 'FRV / KM aansluiting', component: KM_aansluiting },
    { path: '/frv/uren-aansluiting', name: 'FRV / Uren aansluiting', component: Uren_aansluiting },
    { path: '/frv/narekenen-kpi', name: 'FRV / Narekenen KPI', component: NarekenenKPI },

    //{ path: '/wij/controle/uren', name: 'WIJ / Controle / Uren', component: UrenControle },
  ]

};

export default options;
