export default [
    {
      value: 'dimensions',
      label: 'Dimensies',
      class: 'bg-primary',
      children: [
        {
          label: 'Klant',
          value: 'klant',
          children: [
            {
              label: 'Naam',
              value: 'vD_Order.Debtor.Name'
            },
            {
              label: 'Nummer',
              value: 'vD_Order.Debtor.Number'
            },
            {
              label: 'Groep',
              value: 'vD_Order.Debtor.Group'
            },
            {
              label: 'Financieelnummer',
              value: 'vD_Order.Debtor.FinancialNumber'
            },
          ]
        },
        {
          label: 'Order',
          value: 'order',
          children: [
            {
              value: 'vD_Order.OrderNumberDisplay',
              label: 'Order Nummer'
            },
          ]
        },
        {
          label: 'Overig',
          value: 'overig',
          children: [
            {
              value: 'vD_Order.ServiceDescription',
              label: 'Service'
            },
            {
              value: 'vD_Order.Kind',
              label: 'Ordersoort'
            },
            {
              value: 'vD_^Order.AccountManager',
              label: 'Accountmanager'
            },
            {
              value: 'vD_^Order.RelatieBeheerder',
              label: 'Relatiebeheerder'
            },
            {
              value: 'vD_^Order.Team',
              label: 'Team'
            },
            {
              value: 'vD_^Order.SalesBy',
              label: 'Sales By'
            },
            {
              value: 'vD_Order.Afzender',
              label: 'Afzender'
            },
            {
              value: 'vD_Order.Division',
              label: 'Afdeling'
            },
          ]
        },
        {
          label: 'Laad Locatie',
          value: 'laadlocatie',
          children: [
            {
              value: 'vD_Order.LoadingLocation.ID',
              label: 'ID'
            },
            {
              value: 'vD_Order.LoadingLocation.Name',
              label: 'Naam'
            },
            {
              value: 'vD_Order.LoadingLocation.Adress',
              label: 'Adres'
            },
            {
              value: 'vD_Order.LoadingLocation.ZIPcode',
              label: 'Postcode'
            },
            {
              value: 'vD_Order.LoadingLocation.City',
              label: 'Plaats'
            },
            {
              value: 'vD_Order.LoadingLocation.Region',
              label: 'Regio'
            },
            {
              value: 'vD_Order.LoadingLocation.FinancialRegion',
              label: 'F-Regio - Code'
            },
            {
              value: 'vD_Order.LoadingLocation.FinancialRegionDescription',
              label: 'F-Regio - Omschrijving'
            },
            {
              value: 'vD_Order.LoadingLocation.OperationalRegion',
              label: 'O-Regio - Code'
            },
            {
              value: 'vD_Order.LoadingLocation.OperationalRegionDescription',
              label: 'O-Regio - Omschrijving'
            },
            {
              value: 'vD_Order.LoadingLocation.Country',
              label: 'Land'
            },
          ]
        },
        {
          label: 'Los Locatie',
          value: 'loslocatie',
          children: [
            {
              value: 'vD_Order.UnloadingLocation.ID',
              label: 'ID'
            },
            {
              value: 'vD_Order.UnloadingLocation.Name',
              label: 'Naam'
            },
            {
              value: 'vD_Order.UnloadingLocation.Adress',
              label: 'Adres'
            },
            {
              value: 'vD_Order.UnloadingLocation.ZIPcode',
              label: 'Postcode'
            },
            {
              value: 'vD_Order.UnloadingLocation.City',
              label: 'Plaats'
            },
            {
              value: 'vD_Order.UnloadingLocation.Region',
              label: 'Regio'
            },
            {
              value: 'vD_Order.UnloadingLocation.FinancialRegion',
              label: 'F-Regio - Code'
            },
            {
              value: 'vD_Order.UnloadingLocation.FinancialRegionDescription',
              label: 'F-Regio - Omschrijving'
            },
            {
              value: 'vD_Order.UnloadingLocation.OperationalRegion',
              label: 'O-Regio - Code'
            },
            {
              value: 'vD_Order.UnloadingLocation.OperationalRegionDescription',
              label: 'O-Regio - Omschrijving'
            },
            {
              value: 'vD_Order.UnloadingLocation.Country',
              label: 'Land'
            },
          ]
        },
        {
          label: 'Periode',
          value: 'periode',
          children: [
            {
              value: 'vD_Year',
              label: 'Jaar'
            },
            {
              value: 'vD_Quarter',
              label: 'Kwartaal'
            },
            {
              value: 'vD_Month',
              label: 'Maand'
            },
            {
              value: 'vD_Week',
              label: 'Week'
            },
            {
              value: 'vD_Date',
              label: 'Datum'
            },
            {
              value: 'vD_WeekDay',
              label: 'Dag'
            },
          ]
        }
      ]
    },
    {
      label: 'Meetwaarden',
      value: 'measures',
      class: 'bg-danger',
      children: [
        {
          value: 'omzet',
          label: 'Omzet',
          children: [
            {
              value: 'vE_OrderOmzet',
              label: 'Totaal'
            },
          ]
        },
        {
          value: 'kosten',
          label: 'Kosten',
          children: [
            {
              value: 'vE_OrderKosten',
              label: 'Totaal',
            },
            {
              value: 'vE_OrderKostenCharter',
              label: 'Derden',
            },
            {
              value: 'vE_OrderKostenTol',
              label: 'Tol',
            },
            {
              value: 'vE_OrderKostenCoDriver',
              label: 'Bijrijder',
            },
            {
              value: 'vE_OrderKostenWarehouse',
              label: 'Warehouse',
            },
            {
              value: 'vE_OrderKostenOther',
              label: 'Other',
            },
          ]
        },
        {
          value: 'aantal',
          label: 'Aantal',
          children: [
            {
              value: 'vE_OrderAantalOrders',
              label: 'Order',
            },
            {
              value: 'vE_OrderAantalRitten',
              label: 'Ritten',
            },
          ]
        },
        {
          value: 'kpi',
          label: 'KPI',
          children: [
            {
              value: 'vE_KPI_OrderGoedkeur',
              label: 'OK'
            },
  
            {
              value: 'vE_KPI_OrderNormMarginPercent',
              label: 'Norm Marge %'
            },
            {
              value: 'vE_KPI_OrderNormMarginOverOmzet',
              label: 'Norm Marge'
            },
  
  
          ]
        },
        {
          value: 'eigenwagen',
          label: 'Eigen Wagen',
          children: [
            {
              value: 'vE_KPI_OrderOmzetPerUur',
              label: 'Omzet / Uur'
            },
            {
              value: 'vE_KPI_Order_TripOmzetPerUur',
              label: 'Omzet / Uur - rit'
            },
            {
              value: 'vE_KPI_OrderOmzetPerUurCombinatieVolgendeOrder',
              label: 'Omzet / Uur - rondrit'
            },
            {
              value: 'vE_KPI_OrderBezetting',
              label: 'Bezetting'
            },
            {
              value: 'vE_OrderNormTurnoverHour',
              label: 'Norm Omzet / Uur'
            },
          ]
        },
        {
          value: 'uitbesteed',
          label: 'Uitbesteed',
          children: [
            {
              value: 'vE_KPI_OrderTripOutsourced',
              label: '% Uitbesteed'
            },
            {
              value: 'vE_KPI_OrderCharterMarge',
              label: 'Derden Marge'
            },
            {
              value: 'vE_OrderNormCharter',
              label: 'Norm'
            },
          ]
        },
        {
          value: 'potency',
          label: 'Potentie',
          children: [
            {
              value: 'vE_OrderPotency',
              label: 'Totaal'
            },
            {
              value: 'vE_OrderPotencyCommercial',
              label: 'Commercieel'
            },
            {
              value: 'vE_OrderPotencyOperational',
              label: 'Operationeel'
            },
          ]
        },
        {
          value: 'km',
          label: 'KM',
          children: [
            {
              value: 'vE_OrderKM',
              label: 'Totaal'
            },
            {
              value: 'vE_KPI_OrderOmzetPerKM',
              label: 'Omzet / KM'
            },
            {
              value: 'vE_OrderNormTurnoverKM',
              label: 'Norm Omzet / KM'
            }
          ]
        },
        {
          value: 'uren',
          label: 'Uren',
          children: [
            {
              value: 'vE_OrderUren',
              label: 'Totaal (Σ)',
            },
            {
              value: 'vE_OrderUrenLoading',
              label: 'Laden (Σ)',
            },
            {
              value: 'vE_OrderUrenUnloading',
              label: 'Lossen (Σ)',
            },
            {
              value: 'vE_OrderUrenDriving',
              label: 'Rijden (Σ)',
            },
            {
              value: 'vE_OrderUrenOther',
              label: 'Overig (Σ)',
            },
            {
              value: 'vE_OrderUrenCleaning',
              label: 'Spoelen (Σ)',
            },
            {
              value: 'vE_OrderUrenAvg',
              label: 'Totaal (avg)',
            },
            {
              value: 'vE_OrderUrenAvgLoading',
              label: 'Laden (avg)',
            },
            {
              value: 'vE_OrderUrenAvgUnloading',
              label: 'Lossen (avg)',
            },
            {
              value: 'vE_OrderUrenAvgDriving',
              label: 'Rijden (avg)',
            },
            {
              value: 'vE_OrderUrenAvgOther',
              label: 'Overig (avg)',      // $(wActive(Order, 'M_Uren_Rijden_A'))
            },
            {
              value: 'vE_OrderUrenAvgCleaning',
              label: 'Spoelen (avg)',
            },
          ]
        },
        {
          value: 'vergelijkperiode',
          label: 'Voorgaand jaar',
          children: [
            {
              value: 'vE_OrderOmzetVorigJaar',
              label: 'Omzet',
            },
            {
              value: 'vE_OrderAantalOrdersVorigJaar',
              label: 'Order',
            },
            {
              value: 'vE_OrderAantalRittenVorigJaar',
              label: 'Ritten',
            },
            {
              value: 'vE_KPI_OrderGoedkeurVorigJaar',
              label: 'OK'
            },
  
            {
              value: 'vE_KPI_OrderNormMarginPercentVorigJaar',
              label: 'Norm Marge %'
            },
            {
              value: 'vE_KPI_OrderNormMarginOverOmzetVorigJaar',
              label: 'Norm Marge'
            },
            {
              value: 'vE_KPI_OrderOmzetPerUurVorigJaar',
              label: 'Omzet / Uur'
            },
            {
              value: 'vE_KPI_OrderOmzetPerUurCombinatieVolgendeOrderVorigJaar',
              label: 'Omzet / Uur - rondrit'
            },
            {
              value: 'vE_KPI_OrderTripOutsourcedVorigJaar',
              label: '% Uitbesteed'
            },
            {
              value: 'vE_KPI_OrderCharterMargeVorigJaar',
              label: 'Derden Marge'
            },
  
          ],
        },
        {
          value: 'persize',
          label: 'Per Eenheid',
          children: [
            {
              value: 'm3',
              label: 'M3',
              children: [
                {
                  value: 'vE_OrderShipmentSize',
                  label: 'M3'
                },
                {
                  value: 'vE_KPI_OrderOmzetPerZendingGroottePervE_OrderShipmentSize',
                  label: 'Omzet Per M3'
                },
                {
                  value: 'vE_KPI_OrderLaadMinutenPerZendingGrootte',
                  label: 'Laad minuten per M3'
                },
                {
                  value: 'vE_KPI_OrderLosMinutenPerZendingGrootte',
                  label: 'Los minuten per M3'
                },
                {
                  value: 'vE_KPI_OrderMinutenPerZendingGrootte',
                  label: 'Tijd per M3'
                },
              ]
            },
            {
              value: 'kg',
              label: 'KG',
              children: [
                {
                  value: 'vE_OrderKG',
                  label: 'Totaal',
                  help: 'Het totaal aantal kilo\'s vanuit Plan&Go'

                },
                {
                  value: 'vE_OrderKGAvg',
                  label: 'Gemiddelde',
                  help: 'Het gemiddelde aantal kilo\'s vanuit Plan&Go'

                }
              ]
            },
            {
              value: 'colli',
              label: 'Colli',
              children: [
                {
                  value: 'vE_OrderColli',
                  label: 'Totaal',
                  help: 'Het totaal aantal collo vanuit Plan&Go'

                },
                {
                  value: 'vE_OrderColliAvg',
                  label: 'Gemiddelde',
                  help: 'Het gemiddelde aantal collo vanuit Plan&Go'

                }
              ]
            },
          ]
        },
      ]
    }
  ];
  