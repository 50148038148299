import {dimensions, measures} from './qlik/klant/klant';
import {dimensions as sim_dimensions, measures as sim_measures} from './qlik/klant/sim';


export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Klant',
        value: 'klant',
        children: [
          {
            qlik: dimensions.debtor.name,
            label: 'Naam',
            value: 'vD_Order.Debtor.Name'
          },
          {
            qlik: dimensions.debtor.number,
            label: 'Nummer',
            value: 'vD_Order.Debtor.Number'
          }
        ]
      },
      {
        label: 'Order',
        value: 'order',
        children: [
          {
            qlik: dimensions.order.ordernumberdisplay,
            value: 'vD_Order.OrderNumberDisplay',
            label: 'Zending'
          },
          {
            qlik: sim_dimensions.order.department,
            value: 'vD_Order.Department',
            label: 'Afdeling'
          },
          {
            qlik: sim_dimensions.order.previous,
            value: 'vD_Order.PreviousOrderNumberDisplay',
            label: 'Voorgaande Zending'
          },
          {
            qlik: sim_dimensions.order.next,
            value: 'vD_Order.NextOrderNumberDisplay',
            label: 'Volgende Zending'
          },
        ]
      },
      {
        label: 'Laad Locatie',
        value: 'laadlocatie',
        children: [
          {
            qlik: dimensions.loadinglocation.id,
            value: 'vD_Order.LoadingLocation.ID',
            label: 'ID'
          },
          {
            qlik: dimensions.loadinglocation.name,
            value: 'vD_Order.LoadingLocation.Name',
            label: 'Naam'
          },
          {
            qlik: dimensions.loadinglocation.zipcode,
            value: 'vD_Order.LoadingLocation.ZIPcode',
            label: 'Postcode'
          },
          {
            qlik: dimensions.loadinglocation.city,
            value: 'vD_Order.LoadingLocation.City',
            label: 'Plaats'
          },
          {
            qlik: dimensions.loadinglocation.country,
            value: 'vD_Order.LoadingLocation.Country',
            label: 'Land'
          },
        ]
      },
      {
        label: 'Los Locatie',
        value: 'loslocatie',
        children: [
          {
            qlik: dimensions.unloadinglocation.id,
            value: 'vD_Order.UnloadingLocation.ID',
            label: 'ID'
          },
          {
            qlik: dimensions.unloadinglocation.name,
            value: 'vD_Order.UnloadingLocation.Name',
            label: 'Naam'
          },
          {
            qlik: dimensions.unloadinglocation.zipcode,
            value: 'vD_Order.UnloadingLocation.ZIPcode',
            label: 'Postcode'
          },
          {
            qlik: dimensions.unloadinglocation.city,
            value: 'vD_Order.UnloadingLocation.City',
            label: 'Plaats'
          },
          {
            qlik: dimensions.unloadinglocation.country,
            value: 'vD_Order.UnloadingLocation.Country',
            label: 'Land'
          },
        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            qlik: dimensions.period.year,
            value: 'vD_Year',
            label: 'Jaar'
          },
          {
            qlik: dimensions.period.quarter,
            value: 'vD_Quarter',
            label: 'Kwartaal'
          },
          {
            qlik: dimensions.period.month,
            value: 'vD_Month',
            label: 'Maand'
          },
          {
            qlik: dimensions.period.week,
            value: 'vD_Week',
            label: 'Week'
          },
          {
            qlik: dimensions.period.yearweek,
            value: 'vD_YearWeek',
            label: 'Jaar-Week'
          },
          {
            qlik: dimensions.period.date,
            value: 'vD_Date',
            label: 'Datum'
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            qlik: measures.omzet.totaal,
            value: 'vE_OrderOmzet',
            label: 'Totaal'
          },
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            qlik: measures.kosten.totaal,
            value: 'vE_OrderKosten',
            label: 'Totaal',
            help: 'Totaal van de directe kosten.'
          },
          {
            qlik: measures.kosten.charter,
            value: 'vE_OrderKostenCharter',
            label: 'Derden',
            help: 'Kosten van zowel Italië charters (Excel) en expeditie (Winsped).'

          },
          {
            qlik: measures.kosten.rail,
            value: 'vE_OrderKostenRailFerry',
            label: 'Trein',
            help: 'Voorgecalculeerde kosten van treintrajecten uit de tarieventabel van Winsped'

          },
          {
            qlik: measures.kosten.toll,
            value: 'vE_OrderKostenTol',
            label: 'Tol',
            help:'Kosten van tol uit Winsped.'
          },
          {
            qlik: measures.kosten.collect,
            value: 'vE_OrderKostenCleaning',
            label: 'Cleaning',
            help:'Cleaning kosten uit Winsped, mochten die er niet zijn en er wel een cleaning gepland is wordt er met een standaard tarief gerekend.'
          },
          {
            qlik: measures.kosten.warehouse,
            value: 'vE_OrderKostenWarehouse',
            label: 'Containerkosten',
            help: 'Voorgecalculeerde containerkosten die berekend worden door een tarief per dag te vermenigvuldigen met de dagen die een container in gebruik is. De container wordt gekoppeld aan een order na de laadactie van de order. De kosten lopen door op de order zo lang er geen nieuwe order geladen is. Eventuele hoge containerkosten op order niveau kan er op wijzen dat de container langere tijd niet geladen is.'

          },
          {
            qlik: measures.kosten.other,
            value: 'vE_OrderKostenOther',
            label: 'Overig',
            help: 'Overige kosten uit Winsped'

          }

        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            qlik: measures.aantal.orders,
            value: 'vE_OrderAantalOrders',
            label: 'Zendingen',
            help: 'Het aantal zendingen, waarbij een zending een unieke transportopdracht is van A naar B'
          },
          {
            qlik: measures.aantal.ritten,
            value: 'vE_OrderAantalRitten',
            label: 'Ritten',
            help: 'Het aantal ritten behorend bij de geselecteerde zendingen. Hier zien we altijd het totaal. '
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            qlik: measures.kpi.goedkeur,
            value: 'vE_KPI_OrderGoedkeur',
            label: 'OK',
            help: 'Het percentage zendingen dat goedgekeurd is. Wanneer er iets misgaat met een zending wordt deze niet meegenomen in de kengetallen.'

          },
          {
            qlik: measures.kpi.normmarginpercent,
            value: 'vE_KPI_OrderNormMarginPercent',
            label: 'Norm Marge %',
            help: 'De Norm Marge combineert de charter marge en de omzet/uur om deze samen te toetsen tegen de gestelde normen. Voor het eigen werk wordt gekeken hoeveel de omzet / uur afwijkt van de norm voor de omzet/uur. Dit wordt gecombineerd met de afwijking van de chartermarge ten opzichte van de norm voor de chartermarge. Dit wordt enkel uitgerekend voor goedgekeurde orders.'
          },
          {
            qlik: measures.kpi.normmarginomzet,
            value: 'vE_KPI_OrderNormMarginOverOmzet',
            label: 'Norm Marge',
            help: 'De Norm Marge interpoleert het berekende percentage over de gehele omzet.'
          },


        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            qlik: measures.own.omzetperuur,
            value: 'vE_KPI_OrderOmzetPerUur',
            label: 'Omzet / Uur',
            help: 'De omzet / uur is de omzet van het eigen werk minus de directe-kosten van het eigen werk afgezet tegen het aantal ingezette uren. Het gaat hier altijd enkel om goedgekeurde orders. '
          },
          {
            qlik: measures.own.omzetperuurvolgende,
            value: 'vE_KPI_OrderOmzetPerUurCombinatieVolgendeOrder',
            label: 'Omzet / Uur - rondrit',
            help: 'De omzet / uur rondrit is de combinatie van de geselecteerde order + (volgende order of voorgaande order). Aan de rechterzijde kun je in de opties aangeven of je de volgende order, de voorgaande order of zowel de voorgaande in het kengetal omzet/uur rondrit gebruiken. Standaard staat deze instelling op de volgende order. Met dit kengetal kun je een rondrit analyseren zodat de goede heen orders en de slechtere retour orders in combinatie analyseert.'

          },
          {
            qlik: measures.own.bezetting,
            value: 'vE_KPI_OrderBezetting',
            label: 'Bezetting'
          },
          {
            qlik: measures.own.normomzetperuur,
            value: 'vE_OrderNormTurnoverHour',
            label: 'Norm Omzet / Uur',
            help: 'De geconfigureerde norm op orderniveau  voor het eigen werk. De omzet per uur wordt getoetst aan deze norm. '
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            qlik: measures.outsourced.percentage,
            value: 'vE_KPI_OrderTripOutsourced',
            label: '% Uitbesteed',
            help: 'Het percentage dat is uitbesteed. Indien een zending in het voorhaaltraject door een eigen wagen uitgevoerd wordt en het wegbrengen wordt uitbesteed is deze zending voor 50% uitbesteed.'
          },
          {
            qlik: measures.outsourced.marge,
            value: 'vE_KPI_OrderCharterMarge',
            label: 'Derden Marge',
            help: 'De marge op het uitbesteede werk. Dit is de omzet minus de kosten. Hierbij wordt enkel de omzet en de kosten meegenomen indien de order goedgekeurd is. '
          },
          {
            qlik: measures.outsourced.norm,
            value: 'vE_OrderNormCharter',
            label: 'Norm',
            help: 'De geconfigureerde norm op orderniveau voor het uitbestede werk. De Derden Marge wordt getoetst aan deze norm. '
          },
        ]
      },
      {
        value: 'potency',
        label: 'Potentie',
        children: [
          {
            qlik: measures.potentie.totaal,
            value: 'vE_OrderPotency',
            label: 'Totaal'
          },
          {
            qlik: measures.potentie.commercieel,
            value: 'vE_OrderPotencyCommercial',
            label: 'Commercieel'
          },
          {
            qlik: measures.potentie.operationeel,
            value: 'vE_OrderPotencyOperational',
            label: 'Operationeel'
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            qlik: measures.km.km,
            value: 'vE_OrderKM',
            label: 'Totaal',
            help: 'Geeft de totale kilometers op orderniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.km.kmfull,
            value: 'vE_OrderKMFull',
            label: 'Beladen',
            help: 'Geeft de beladen kilometers op orderniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.km.kmempty,
            value: 'vE_OrderKMEmpty',
            label: 'Onbeladen',
            help: 'Geeft de onbeladen kilometers op orderniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

          },
          {
            qlik: measures.km.omzetperkm,
            value: 'vE_KPI_OrderOmzetPerKM',
            label: 'Omzet / KM',
          },
          {
            qlik: measures.km.omzetperkmfull,
            value: 'vE_KPI_OrderOmzetPerKMFull',
            label: 'Omzet / beladen KM',
          },
          {
            qlik: measures.km.belading,
            value: 'vE_KPI_OrderBelading',
            label: '% Beladen'
          },
          {
            qlik: measures.km.normomzetperkm,
            value: 'vE_OrderNormTurnoverKM',
            label: 'Norm Omzet / KM',
            help: 'De geconfigureerde norm op orderniveau voor de Omzet / KM.'
          },
          {
            qlik: measures.km.normomzetperkmfull,
            value: 'vE_OrderNormTurnoverKMFull',
            label: 'Norm Omzet / beladen KM',
            help: 'De geconfigureerde norm op orderniveau voor de Omzet / beladen KM.'

          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            qlik: measures.uren.totaal,
            value: 'vE_OrderUren',
            label: 'Totaal (Σ)',
            help: 'Het totaal aantal uren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.loading,
            value: 'vE_OrderUrenLoading',
            label: 'Laden (Σ)',
            help: 'Het totaal aantal laaduren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.unloading,
            value: 'vE_OrderUrenUnloading',
            label: 'Lossen (Σ)',
            help: 'Het totaal aantal losuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.driving,
            value: 'vE_OrderUrenDriving',
            label: 'Rijden (Σ)',
            help: 'Het totaal aantal rijuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.other,
            value: 'vE_OrderUrenOther',
            label: 'Overig (Σ)',
            help: 'Het totaal aantal overige uren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.avg_totaal,
            value: 'vE_OrderUrenAvg',
            label: 'Totaal (avg)',
            help: 'Het gemiddeld aantal uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.avg_loading,
            value: 'vE_OrderUrenAvgLoading',
            label: 'Laden (avg)',
            help: 'Het gemiddeld aantal laaduren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.avg_unloading,
            value: 'vE_OrderUrenAvgUnloading',
            label: 'Lossen (avg)',
            help: 'Het gemiddeld aantal losuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.avg_driving,
            value: 'vE_OrderUrenAvgDriving',
            label: 'Rijden (avg)',
            help: 'Het gemiddeld aantal rijuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.avg_other,
            value: 'vE_OrderUrenAvgOther',
            label: 'Overig (avg)',    
            help: 'Het gemiddeld aantal overige uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
        ]
      },
      {
        value: 'vergelijkperiode',
        label: 'Voorgaand jaar',
        children: [
          {
            qlik: measures.previousyear.omzet,
            value: 'vE_OrderOmzetVorigJaar',
            label: 'Omzet',
          },
          {
            qlik: measures.previousyear.orders,
            value: 'vE_OrderAantalOrdersVorigJaar',
            label: 'Order',
          },
          {
            qlik: measures.previousyear.ritten,
            value: 'vE_OrderAantalRittenVorigJaar',
            label: 'Ritten',
          },
          {
            qlik: measures.previousyear.goedkeur,
            value: 'vE_KPI_OrderGoedkeurVorigJaar',
            label: 'OK'
          },
          {
            qlik: measures.previousyear.normmarge,
            value: 'vE_KPI_OrderNormMarginPercentVorigJaar',
            label: 'Norm Marge %'
          },
          {
            qlik: measures.previousyear.normmargeomzet,
            value: 'vE_KPI_OrderNormMarginOverOmzetVorigJaar',
            label: 'Norm Marge'
          },
          {
            qlik: measures.previousyear.omzetperuur,
            value: 'vE_KPI_OrderOmzetPerUurVorigJaar',
            label: 'Omzet / Uur'
          },
          {
            qlik: measures.previousyear.volgendeorder,
            value: 'vE_KPI_OrderOmzetPerUurCombinatieVolgendeOrderVorigJaar',
            label: 'Omzet / Uur - rondrit'
          },
          {
            qlik: measures.previousyear.uitbesteed,
            value: 'vE_KPI_OrderTripOutsourcedVorigJaar',
            label: '% Uitbesteed'
          },
          {
            qlik: measures.previousyear.chargermarge,
            value: 'vE_KPI_OrderCharterMargeVorigJaar',
            label: 'Derden Marge'
          },

        ],
      },
      {
        value: 'persize',
        label: 'Per Eenheid',
        children: [
          {
            qlik: measures.persize.shipmentsize,
            value: 'vE_OrderShipmentSize',
            label: 'Ton',
            help: 'Het totaal aantal ton vanuit Winsped'
          },
          {
            qlik: measures.persize.omzet_shipmentsize,
            value: 'vE_KPI_OrderOmzetPerZendingGroottePervE_OrderShipmentSize',
            label: 'Omzet Per Ton',
            help: 'De totale omzet / gedeeld door het totale tonnage'
          },
          {
            qlik: measures.persize.loading_shipmentsize,
            value: 'vE_KPI_OrderLaadMinutenPerZendingGrootte',
            label: 'Laad minuten per Ton',
            help: 'De toegekende laadtijd / gedeeld door het totale tonnage'

          },
          {
            qlik: measures.persize.unloading_shipmentsize,
            value: 'vE_KPI_OrderLosMinutenPerZendingGrootte',
            label: 'Los minuten per ton',
            help: 'De toegekende lostijd / gedeeld door het totale tonnage'

          },
          {
            qlik: measures.persize.time_shipmentsize,
            value: 'vE_KPI_OrderMinutenPerZendingGrootte',
            label: 'Tijd per ton',
            help: 'De toegekende tijd / gedeeld door het totale tonnage'
          },
        ]
      },
    ]
  }
];
