export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Order',
        value: 'order',
        children: [
          {
            value: 'vD_Order.OrderNumberDisplay',
            label: 'Order Nummer'
          }, 
          {
            label: 'Klant',
            value: 'klant',
            children: [
              {
                label: 'Naam',
                value: 'vD_Order.Debtor.Name'
              },
              {
                label: 'Nummer',
                value: 'vD_Order.Debtor.Number'
              }
            ]
          },
          {
            label: 'Laad Locatie',
            value: 'laadlocatie',
            children: [
              {
                value: 'vD_Order.LoadingLocation.ZIPcode',
                label: 'Postcode'
              },
              {
                value: 'vD_Order.LoadingLocation.City',
                label: 'Plaats'
              },
              {
                value: 'vD_Order.LoadingLocation.District',
                label: 'Gemeente'
              },
              {
                value: 'vD_Order.LoadingLocation.Province',
                label: 'Provincie'
              },
              {
                value: 'vD_Order.LoadingLocation.Country',
                label: 'Land'
              },
              {
                value: 'vD_Order.LoadingLocation.Land',
                label: 'Land (ISO)'
              },
            ]
          },
          {
            label: 'Los Locatie',
            value: 'loslocatie',
            children: [
              {
                value: 'vD_Order.UnloadingLocation.ZIPcode',
                label: 'Postcode'
              },
              {
                value: 'vD_Order.UnloadingLocation.City',
                label: 'Plaats'
              },
              {
                value: 'vD_Order.UnloadingLocation.District',
                label: 'Gemeente'
              },
              {
                value: 'vD_Order.UnloadingLocation.Province',
                label: 'Provincie'
              },
              {
                value: 'vD_Order.UnloadingLocation.Country',
                label: 'Land'
              },
              {
                value: 'vD_Order.UnloadingLocation.Land',
                label: 'Land (ISO)'
              },
            ]
          },
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            value: 'vD_Trip.TripNumber',
            label: 'Technisch Trip Nummer'
          },
          {
            label: 'Chauffeur',
            value: 'driver',
            children: [
              {
                label: 'Naam',
                value: 'vD_Driver.Name'
              },
              {
                label: 'Nummer',
                value: 'vD_Driver.Number'
              }
            ]
          },
          {
            label: 'Auto',
            value: 'vehicle',
            children: [
              {
                label: 'Naam',
                value: 'vD_Vehicle.Number'
              },
              {
                label: 'Nummer',
                value: 'vD_Vehicle.Name'
              }
            ]
          },
          {
            label: 'Crediteur',
            value: 'crediteur',
            children: [
              {
                label: 'Naam',
                value: 'vD_Trip.CreditorName'
              },
              {
                label: 'Nummer',
                value: 'vD_Trip.CreditorNumber'
              }
            ]
          },
          {
            label: 'Trailer',
            value: 'trailer',
            children: [
              {
                label: 'Nummer',
                value: 'vD_Trip.Trailer.Number'
              },
            ]
          },

        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            value: 'vD_Year',
            label: 'Jaar'
          },
          {
            value: 'vD_Quarter',
            label: 'Kwartaal'
          },
          {
            value: 'vD_Month',
            label: 'Maand'
          },
          {
            value: 'vD_Period',
            label: 'Periode'
          },
          {
            value: 'vD_Week',
            label: 'Week'
          },
          {
            value: 'vD_Date',
            label: 'Datum'
          },
          {
            value: 'vD_Weekday',
            label: 'Dag'
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            value: 'vE_OrderTripOmzet',
            label: 'Totaal'
          },
          {
            value: 'vE_OrderTripOmzet.Freight',
            label: 'Vracht'
          },
          {
            value: 'vE_OrderTripOmzet.Fuel',
            label: 'Brandstof'
          },
          {
            value: 'vE_OrderTripOmzet.ExtraKosten',
            label: 'Extra Kosten'
          },
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            value: 'vE_OrderTripKosten',
            label: 'Totaal',
          },
          {
            value: 'vE_OrderTripKostenCharter',
            label: 'Charter',
          },
          {
            value: 'vE_OrderTripKostenTol',
            label: 'Tol',
          },
          {
            value: 'vE_OrderTripKostenWarehouse',
            label: 'Warehouse',
          },
          {
            value: 'vE_OrderTripKostenOther',
            label: 'Overig',
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            value: 'vE_OrderTripAantalOrders',
            label: 'Zendingen',
          },
          {
            value: 'vE_OrderTripAantalRitten',
            label: 'Ritten',
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            value: 'vE_KPI_OrderTripGoedkeur',
            label: 'OK'
          },
          {
            value: 'vE_KPI_OrderTripNormMarginPercent',
            label: 'Norm Marge %'
          },
          {
            value: 'vE_KPI_OrderTripNormMarginOverOmzet',
            label: 'Norm Marge'
          },
        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            value: 'vE_KPI_OrderTripOmzetPerUur',
            label: 'Omzet / Uur'
          },
          {
            value: 'vE_OrderTripNormTurnoverHour',
            label: 'Norm Omzet / Uur'
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            value: 'vE_KPI_OrderTripOutsourced',
            label: '% Uitbesteed'
          },
          {
            value: 'vE_KPI_OrderTripCharterMarge',
            label: 'Derden Marge'
          },
          {
            value: 'vE_OrderTripNormCharter',
            label: 'Norm'
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            value: 'vE_OrderTripKM',
            label: 'Totaal'
          },
          {
            value: 'vE_KPI_OrderTripOmzetPerKM',
            label: 'Omzet / KM'
          },
          {
            value: 'vE_OrderTripNormTurnoverKM',
            label: 'Norm Omzet / KM'
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            value: 'vE_OrderTripUren',
            label: 'Totaal (Σ)',
          },
          {
            value: 'vE_OrderTripUrenLoading',
            label: 'Laden (Σ)',
          },
          {
            value: 'vE_OrderTripUrenUnloading',
            label: 'Lossen (Σ)',
          },
          {
            value: 'vE_OrderTripUrenDriving',
            label: 'Rijden (Σ)',
          },
          {
            value: 'vE_OrderTripUrenOther',
            label: 'Overig (Σ)',
          },
          {
            value: 'vE_OrderTripUrenAvg',
            label: 'Totaal (avg)',
          },
          {
            value: 'vE_OrderTripUrenAvgLoading',
            label: 'Laden (avg)',
          },
          {
            value: 'vE_OrderTripUrenAvgUnloading',
            label: 'Lossen (avg)',
          },
          {
            value: 'vE_OrderTripUrenAvgDriving',
            label: 'Rijden (avg)',
          },
          {
            value: 'vE_OrderTripUrenAvgOther',
            label: 'Overig (avg)', 
          },
        ]
      },
    ]
  }
];
