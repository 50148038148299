const dimensions = {
    order: {
      department: {
        "qLibraryId": "",
        "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
            "[$(vD_Order.Department)]"
          ],
          "qFieldLabels": [
            ""
          ],
          "qSortCriterias": [
            {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_Order.Department)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
            "auto": true,
            "align": "left"
          },
          "representation": {
            "type": "text",
            "urlLabel": ""
          }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
            "qv": "10"
          },
          "qOtherLimit": {
            "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
            "qv": ""
          }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
          "qv": "Overige"
        },
        "qTotalLabel": {
          "qv": ""
        },
        "qCalcCond": {
          "qv": ""
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      previous: {
        "qLibraryId": "",
        "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
            "[$(vD_Order.PreviousOrderNumberDisplay)]"
          ],
          "qFieldLabels": [
            ""
          ],
          "qSortCriterias": [
            {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_Order.PreviousOrderNumberDisplay)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
            "auto": true,
            "align": "left"
          },
          "representation": {
            "type": "text",
            "urlLabel": ""
          }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
            "qv": "10"
          },
          "qOtherLimit": {
            "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
            "qv": ""
          }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
          "qv": "Overige"
        },
        "qTotalLabel": {
          "qv": ""
        },
        "qCalcCond": {
          "qv": ""
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      next: {
        "qLibraryId": "",
        "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
            "[$(vD_Order.NextOrderNumberDisplay)]"
          ],
          "qFieldLabels": [
            ""
          ],
          "qSortCriterias": [
            {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_Order.NextOrderNumberDisplay)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
            "auto": true,
            "align": "left"
          },
          "representation": {
            "type": "text",
            "urlLabel": ""
          }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
            "qv": "10"
          },
          "qOtherLimit": {
            "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
            "qv": ""
          }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
          "qv": "Overige"
        },
        "qTotalLabel": {
          "qv": ""
        },
        "qCalcCond": {
          "qv": ""
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      }

    },
};
const measures = {
};

export {measures, dimensions};