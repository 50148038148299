import qlik from '../orders/qlik';
import {dimensions, measures} from './qlik/qlik';

export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Chauffeur',
        value: 'driver',
        children: [
          {
            label: 'Naam',
            new_value: 'dimensions.driver.name',
            value: 'vD_Driver.Name',
            qlik: dimensions.driver.name
          },
          {
            label: 'Nummer',
            new_value: 'dimensions.driver.number',
            value: 'vD_Driver.Number',
            qlik: dimensions.driver.number
          }
        ]
      },
      {
        label: 'Auto',
        value: 'vehicle',
        children: [
          {
            label: 'Nummer',
            new_value: 'dimensions.vehicle.number',
            value: 'vD_Vehicle.Number',
            qlik: dimensions.vehicle.number
          },
          {
            label: 'Naam',
            new_value: 'dimensions.vehicle.name',
            value: 'vD_Vehicle.Name',
            qlik: dimensions.vehicle.name
          },
          {
            label: 'Type',
            new_value: 'dimensions.vehicle.type',
            value: 'vD_Trip.Vehicle.Type',
            qlik: dimensions.vehicle.type
          }
        ]
      },
      {
        label: 'Crediteur',
        value: 'crediteur',
        children: [
          {
            label: 'Nummer',
            new_value: 'dimensions.charter.number',
            value: 'vD_Trip.Creditor.Number',
            qlik: dimensions.charter.number
          },
          {
            label: 'Naam',
            new_value: 'dimensions.charter.name',
            value: 'vD_Trip.Creditor.Name',
            qlik: dimensions.charter.name
          },
          {
            label: 'Soort', 
            new_value: 'dimensions.charter.kinddescription',
            value: 'vD_Trip.Creditor.Kind.Description',
            qlik: dimensions.charter.kinddescription
          },
          {
            label: 'Zoekcode', 
            new_value: 'dimensions.charter.searchcode',
            value: 'vD_Trip.Creditor.SearchCode',
            qlik: dimensions.charter.searchcode
          },
          {
            label: 'Type',
            help: 'Chartertype op basis van de geboekte kosten. Waarbij onderscheid is gemaakt tussen kilometer, uren en rit-charters',
            value: 'vD_Trip.Creditor.Type',
            qlik: dimensions.charter.type,
            new_value: 'dimensions.charter.type'
          },
        ]
      },
      {
        label: 'Trailer',
        value: 'trailer',
        children: [
          {
            label: 'Nummer',
            new_value: 'dimensions.trailer.number',
            value: 'vD_Trip.Trailer.Number',
            qlik: dimensions.trailer.number
          },
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            new_value: 'dimensions.trip.number',
            value: 'vD_Trip.TripNumber',
            label: 'Rit Nummer',
            qlik: dimensions.trip.number
          },
          {
            new_value: 'dimensions.trip.resourcecombination',
            value: 'vD_Trip.ResourceCombination',
            label: 'Resource Combination',
            qlik: dimensions.trip.resourcecombination
          },
          {
            new_value: 'dimensions.trip.plangroup',
            value: 'vD_Trip.PlanGroup',
            label: 'Plangroep',
            qlik: dimensions.trip.plangroup
          },
          {
            qlik:dimensions.trip.plandivision,
            new_value: 'dimensions.trip.plandivision',
            value: 'vD_Trip.PlanDivision',
            label: 'Planafdeling',
            help: 'Verzameling van plangroepen die bij elkaar horen. Op basis van mail F. Rossier - dd 22-10-2020'
          },
          {
            qlik:dimensions.trip.planregio,
            new_value: 'dimensions.trip.planregio',
            value: 'vD_Trip.PlanRegio',
            label: 'Planregio',
            help: 'Indeling op geografische regio op basis van de plangroep van een order. Verdeling: Internationaal / Binnenland.'

          }
        ]
      },
      {
        label: 'Start Locatie',
        value: 'laadlocatie',
        children: [
          {
            new_value: 'dimensions.startlocation.name',
            value: 'vD_Trip.StartLocation.Name',
            label: 'Naam',
            qlik: dimensions.startlocation.name
          },
          {
            new_value: 'dimensions.startlocation.adress',
            value: 'vD_Trip.StartLocation.Adress',
            label: 'Adres',
            qlik: dimensions.startlocation.adress
          },
          {
            new_value: 'dimensions.startlocation.searchcode',
            value: 'vD_Trip.StartLocation.SearchCode',
            label: 'Zoekcode',
            qlik: dimensions.startlocation.searchcode
          },
          {
            new_value: 'dimensions.startlocation.zipcode',
            value: 'vD_Trip.StartLocation.ZIPcode',
            label: 'Postcode',
            qlik: dimensions.startlocation.zipcode
          },
          {
            new_value: 'dimensions.startlocation.city',
            value: 'vD_Trip.StartLocation.City',
            label: 'Plaats',
            qlik: dimensions.startlocation.city
          },
          {
            new_value: 'dimensions.startlocation.district',
            value: 'vD_Trip.StartLocation.District',
            label: 'Gemeente',
            qlik: dimensions.startlocation.district
          },
          {
            new_value: 'dimensions.startlocation.province',
            value: 'vD_Trip.StartLocation.Province',
            label: 'Provincie',
            qlik: dimensions.startlocation.province
          },
          {
            new_value: 'dimensions.startlocation.country',
            value: 'vD_Trip.StartLocation.Country',
            label: 'Land',
            qlik: dimensions.startlocation.country
          },
          {
            new_value: 'dimensions.startlocation.land',
            value: 'vD_Trip.StartLocation.Land',
            label: 'Land (ISO)',
            qlik: dimensions.startlocation.land
          }
        ]
      },
      {
        label: 'Eind Locatie',
        value: 'loslocatie',
        children: [
          {
            new_value: 'dimensions.endlocation.name',
            value: 'vD_Trip.EndLocation.Name',
            label: 'Name',
            qlik: dimensions.endlocation.name
          },
          {
            new_value: 'dimensions.endlocation.adress',
            value: 'vD_Trip.EndLocation.Adress',
            label: 'Adres',
            qlik: dimensions.endlocation.adress
          },
          {
            new_value: 'dimensions.endlocation.searchcode',
            value: 'vD_Trip.EndLocation.SearchCode',
            label: 'Zoekcode',
            qlik: dimensions.endlocation.searchcode
          },
          {
            new_value: 'dimensions.endlocation.zipcode',
            value: 'vD_Trip.EndLocation.ZIPcode',
            label: 'Postcode',
            qlik: dimensions.endlocation.zipcode
          },
          {
            new_value: 'dimensions.endlocation.city',
            value: 'vD_Trip.EndLocation.City',
            label: 'Plaats',
            qlik: dimensions.endlocation.city
          },
          {
            new_value: 'dimensions.endlocation.district',
            value: 'vD_Trip.EndLocation.District',
            label: 'Gemeente',
            qlik: dimensions.endlocation.district
          },
          {
            new_value: 'dimensions.endlocation.province',
            value: 'vD_Trip.EndLocation.Province',
            label: 'Provincie',
            qlik: dimensions.endlocation.province
          },
          {
            new_value: 'dimensions.endlocation.country',
            value: 'vD_Trip.EndLocation.Country',
            label: 'Land',
            qlik: dimensions.endlocation.country
          },
          {
            new_value: 'dimensions.endlocation.land',
            value: 'vD_Trip.EndLocation.Land',
            label: 'Land (ISO)',
            qlik: dimensions.endlocation.land
          }
        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            new_value: 'dimensions.period.year',
            value: 'vD_Year',
            label: 'Jaar',
            qlik: dimensions.period.year
          },
          {
            new_value: 'dimensions.period.quarter',
            value: 'vD_Quarter',
            label: 'Kwartaal',
            qlik: dimensions.period.quarter
          },
          {
            new_value: 'dimensions.period.month',
            value: 'vD_Month',
            label: 'Maand',
            qlik: dimensions.period.month
          },
          {
            new_value: 'dimensions.period.week',
            value: 'vD_Week',
            label: 'Week',
            qlik: dimensions.period.week
          },
          {
            new_value: 'dimensions.period.date',
            value: 'vD_Date',
            label: 'Datum',
            qlik: dimensions.period.date
          },
          {
            new_value: 'dimensions.period.weekday',
            value: 'vD_Weekday',
            label: 'Dag',
            qlik: dimensions.period.weekday
          }
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            new_value: 'measures.omzet.totaal',
            value: 'vE_TripOmzet',
            label: 'Totaal',
            qlik: measures.omzet.totaal
          },
          {
            new_value: 'measures.omzet.freight',
            value: 'vE_TripOmzet.Freight',
            label: 'Vracht',
            qlik: measures.omzet.freight
          },
          {
            new_value: 'measures.omzet.fuel',
            value: 'vE_TripOmzet.Fuel',
            label: 'Brandstof',
            qlik: measures.omzet.fuel
          },
          {
            new_value: 'measures.omzet.extra',
            value: 'vE_TripOmzet.ExtraKosten',
            label: 'Extra Kosten',
            qlik: measures.omzet.extra
          },
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            new_value: 'measures.kosten.totaal',
            value: 'vE_TripKosten',
            label: 'Totaal',
            qlik: measures.kosten.totaal
          },
          {
            new_value: 'measures.kosten.charter',
            value: 'vE_TripKostenCharter',
            label: 'Charter',
            qlik: measures.kosten.charter
          },
          {
            new_value: 'measures.kosten.toll',
            value: 'vE_TripKostenTol',
            label: 'Tol',
            qlik: measures.kosten.toll
          },
          {
            new_value: 'measures.kosten.warehouse',
            value: 'vE_TripKostenWarehouse',
            label: 'Crossdock',
            qlik: measures.kosten.warehouse
          },
          {
             new_value: 'measures.kosten.other',
             value: 'vE_TripKostenOther',
             label: 'Overig',
             qlik: measures.kosten.other
          }
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            new_value: 'measures.aantal.dossiers',
            value: 'vE_TripAantalDossiers',
            label: 'Dossiers',
            qlik: measures.aantal.dossiers
          },
          {
            new_value: 'measures.aantal.orders',
            value: 'vE_TripAantalOrders',
            label: 'Zendingen',
            qlik: measures.aantal.orders
          },
          {
            new_value: 'measures.aantal.ritten',
            value: 'vE_TripAantalRitten',
            label: 'Ritten',
            qlik: measures.aantal.ritten
          },
          {
            qlik: measures.aantal.afwijkingen,
            new_value: 'measures.aantal.afwijkingen',
            value: 'vE_TripAantalAfwijkingen',
            label: 'Afwijkingen',
            help: 'Het aantal afwijkingen geregistreerd de zendingen behorend bij de rit.'
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            new_value: 'measures.kpi.goedkeur',
            value: 'vE_KPI_TripGoedkeur',
            label: 'OK',
            qlik: measures.kpi.goedkeur
          },

          {
            new_value: 'measures.kpi.normmarginpercent',
            value: 'vE_KPI_TripNormMarginPercent',
            label: 'Norm Marge %',
            qlik: measures.kpi.normmarginpercent
          },
          {
            new_value: 'measures.kpi.normmarginomzet',
            value: 'vE_KPI_TripNormMarginOverOmzet',
            label: 'Norm Marge',
            qlik: measures.kpi.normmarginomzet
          }

        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            new_value: 'measures.own.omzetperuur',
            value: 'vE_KPI_TripOmzetPerUur',
            label: 'Omzet / Uur',
            qlik: measures.own.omzetperuur
          },
          {
            new_value: 'measures.own.normomzetperuur',
            value: 'vE_TripNormTurnoverHour',
            label: 'Norm Omzet / Uur',
            qlik: measures.own.normomzetperuur
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            new_value: 'measures.outsourced.percentage',
            value: 'vE_KPI_TripTripOutsourced',
            label: '% Uitbesteed',
            qlik: measures.outsourced.percentage
          },
          {
            new_value: 'measures.outsourced.marge',
            value: 'vE_KPI_TripCharterMarge',
            label: 'Derden Marge',
            qlik: measures.outsourced.marge
          },
          {
            new_value: 'measures.outsourced.norm',
            value: 'vE_TripNormCharter',
            label: 'Norm',
            qlik: measures.outsourced.norm
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            new_value: 'measures.km.km',
            value: 'vE_TripKM',
            label: 'Totaal',
            qlik: measures.km.km
          },
          {
            new_value: 'measures.km.omzetperkm',
            value: 'vE_KPI_TripOmzetPerKM',
            label: 'Omzet / KM',
            qlik: measures.km.omzetperkm
          },
          {
            new_value: 'measures.km.normomzetperkm',
            value: 'vE_TripNormTurnoverKM',
            label: 'Norm Omzet / KM',
            qlik: measures.km.normomzetperkm
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            new_value: 'measures.uren.totaal',
            value: 'vE_TripUren',
            label: 'Totaal (Σ)',
            qlik: measures.uren.totaal
          },
          {
            new_value: 'measures.uren.loading',
            value: 'vE_TripUrenLoading',
            label: 'Laden (Σ)',
            qlik: measures.uren.loading
          },
          {
            new_value: 'measures.uren.unloading',
            value: 'vE_TripUrenUnloading',
            label: 'Lossen (Σ)',
            qlik: measures.uren.unloading
          },
          {
            new_value: 'measures.uren.driving',
            value: 'vE_TripUrenDriving',
            label: 'Rijden (Σ)',
            qlik: measures.uren.driving
          },
          {
            new_value: 'measures.uren.other',
            value: 'vE_TripUrenOther',
            label: 'Overig (Σ)',
            qlik: measures.uren.other
          },
          {
            new_value: 'measures.uren.avg_totaal',
            value: 'vE_TripUrenAvg',
            label: 'Totaal (avg)',
            qlik: measures.uren.avg_totaal
          },
          {
            new_value: 'measures.uren.avg_loading',
            value: 'vE_TripUrenAvgLoading',
            label: 'Laden (avg)',
            qlik: measures.uren.avg_loading
          },
          {
            new_value: 'measures.uren.avg_unloading',
            value: 'vE_TripUrenAvgUnloading',
            label: 'Lossen (avg)',
            qlik: measures.uren.avg_unloading
          },
          {
            new_value: 'measures.uren.avg_driving',
            value: 'vE_TripUrenAvgDriving',
            label: 'Rijden (avg)',
            qlik: measures.uren.avg_driving
          },
          {
            new_value: 'measures.uren.avg_other',
            value: 'vE_TripUrenAvgOther',
            label: 'Overig (avg)', 
            qlik: measures.uren.avg_other     
          }
        ]
      }
    ]
  }
];
