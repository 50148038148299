export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Opdrachtgever',
        value: 'customer',
        children: [
          {
            label: 'Naam',
            value: 'vD_Order.Customer.Name'
          },
          {
            label: 'Nummer',
            value: 'vD_Order.Customer.Number'
          },
        ]
      },
      {
        label: 'Debiteur',
        value: 'debtor',
        children: [
          {
            label: 'Naam',
            value: 'vD_Order.Debtor.Name'
          },
          {
            label: 'Nummer',
            value: 'vD_Order.Debtor.Number'
          }
        ]
      },
      {
        label: 'Order',
        value: 'order',
        children: [
          {
            value: 'vD_Order.Kind',
            label: 'Soort'
          },
          {
            value: 'vD_Order.OrderNumber',
            label: 'Technisch %Order%'
          },
          {
            value: 'vD_Order.Dossier',
            label: 'Dossier'
          },
          {
            value: 'vD_Order.OrderNumberDisplay',
            label: 'Zending'
          },
          {
            value: 'vD_Order.Company.Name',
            label: 'Bedrijf'
          },
          {
            value: 'vD_Order.Company.Code',
            label: 'Bedrijfscode'
          },

          {
            value: 'vD_Order.Reference',
            label: 'Reference'
          },
          {
            value: 'vD_Order.PlanGroup',
            label: 'Plangroep'
          },
          {
            value: 'vD_Order.LandGroup',
            label: 'Planafdeling'
          },
          {
            value: 'vD_Order.Status',
            label: 'Status'
          },
          {
            value: 'vD_Order.Kind',
            label: 'Soort'
          },
        ]
      },
      {
        label: 'Laad Locatie',
        value: 'laadlocatie',
        children: [
          {
            value: 'vD_Order.LoadingLocation.Name',
            label: 'Naam'
          },
          {
            value: 'vD_Order.LoadingLocation.Adress',
            label: 'Adres'
          },
          {
            value: 'vD_Order.LoadingLocation.SearchCode',
            label: 'Zoekcode'
          },
          {
            value: 'vD_Order.LoadingLocation.ZIPcode',
            label: 'Postcode'
          },
          {
            value: 'vD_Order.LoadingLocation.City',
            label: 'Plaats'
          },
          {
            value: 'vD_Order.LoadingLocation.District',
            label: 'Gemeente'
          },
          {
            value: 'vD_Order.LoadingLocation.Province',
            label: 'Provincie'
          },
          {
            value: 'vD_Order.LoadingLocation.Country',
            label: 'Land'
          },
          {
            value: 'vD_Order.LoadingLocation.Land',
            label: 'Land (ISO)'
          },
        ]
      },
      {
        label: 'Los Locatie',
        value: 'loslocatie',
        children: [
          {
            value: 'vD_Order.UnloadingLocation.Name',
            label: 'Naam'
          },
          {
            value: 'vD_Order.UnloadingLocation.Adress',
            label: 'Adres'
          },
          {
            value: 'vD_Order.UnloadingLocation.SearchCode',
            label: 'Zoekcode'
          },
          {
            value: 'vD_Order.UnloadingLocation.ZIPcode',
            label: 'Postcode'
          },
          {
            value: 'vD_Order.UnloadingLocation.City',
            label: 'Plaats'
          },
          {
            value: 'vD_Order.UnloadingLocation.District',
            label: 'Gemeente'
          },
          {
            value: 'vD_Order.UnloadingLocation.Province',
            label: 'Provincie'
          },
          {
            value: 'vD_Order.UnloadingLocation.Country',
            label: 'Land'
          },
          {
            value: 'vD_Order.UnloadingLocation.Land',
            label: 'Land (ISO)'
          },
        ]
      },
      {
        label: 'Goederen',
        value: 'goederen',
        children: [
          {
            value: 'vD_Product',
            label: 'Product'
          },
          {
            value: 'vD_ProductDescription',
            label: 'Product Omschrijving'
          },
        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            value: 'vD_Year',
            label: 'Jaar'
          },
          {
            value: 'vD_Quarter',
            label: 'Kwartaal'
          },
          {
            value: 'vD_Month',
            label: 'Maand'
          },
          {
            value: 'vD_Week',
            label: 'Week'
          },
          {
            value: 'vD_YearWeek',
            label: 'Jaar-Week'
          },
          {
            value: 'vD_Date',
            label: 'Datum'
          },
          {
            value: 'vD_Weekday',
            label: 'Weekday'
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            value: 'vE_OrderOmzet',
            label: 'Totaal'
          },
          {
            value: 'vE_OrderOmzetFreight',
            label: 'Vracht'
          },
          {
            value: 'vE_OrderOmzetFuel',
            label: 'Brandstof'
          },{
            value: 'vE_OrderOmzetMaut',
            label: 'Tol'
          },{
            value: 'vE_OrderOmzetOther',
            label: 'Overig'
          },
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            value: 'vE_OrderKosten',
            label: 'Totaal',
          },
          {
            value: 'vE_OrderKostenCharter',
            label: 'Derden',
          },
          {
            value: 'vE_OrderKostenRailFerry',
            label: 'Trein',
          },
          {
            value: 'vE_OrderKostenTol',
            label: 'Tol',
          },
          {
            value: 'vE_OrderKostenJIB',
            label: 'JIB',
          },
          {
            value: 'vE_OrderKostenOther',
            label: 'Overig',
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            value: 'vE_OrderAantalDossiers',
            label: 'Dossiers',
          },
          {
            value: 'vE_OrderAantalOrders',
            label: 'Zendingen',
          },
          {
            value: 'vE_OrderAantalRitten',
            label: 'Ritten',
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            value: 'vE_KPI_OrderGoedkeur',
            label: 'OK'
          },

          {
            value: 'vE_KPI_OrderNormMarginPercent',
            label: 'Norm Marge %'
          },
          {
            value: 'vE_KPI_OrderNormMarginOverOmzet',
            label: 'Norm Marge'
          },


        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            value: 'vE_KPI_OrderOmzetPerUur',
            label: 'Omzet / Uur'
          },
          {
            value: 'vE_KPI_Order_TripOmzetPerUur',
            label: 'Omzet / Uur - rit'
          },
          {
            value: 'vE_KPI_OrderOmzetPerUurCombinatieVolgendeOrder',
            label: 'Omzet / Uur - rondrit'
          },
          {
            value: 'vE_KPI_OrderBezetting',
            label: 'Bezetting'
          },
          {
            value: 'vE_OrderNormTurnoverHour',
            label: 'Norm Omzet / Uur'
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            value: 'vE_KPI_OrderTripOutsourced',
            label: '% Uitbesteed'
          },
          {
            value: 'vE_KPI_OrderCharterMarge',
            label: 'Derden Marge'
          },
          {
            value: 'vE_OrderNormCharter',
            label: 'Norm'
          },
        ]
      },
      {
        value: 'potency',
        label: 'Potentie',
        children: [
          {
            value: 'vE_OrderPotency',
            label: 'Totaal'
          },
          {
            value: 'vE_OrderPotencyCommercial',
            label: 'Commercieel'
          },
          {
            value: 'vE_OrderPotencyOperational',
            label: 'Operationeel'
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            value: 'vE_OrderKM',
            label: 'Totaal'
          },
          {
            value: 'vE_OrderKMFull',
            label: 'Beladen'
          },
          {
            value: 'vE_OrderKMEmpty',
            label: 'Onbeladen'
          },
          {
            value: 'vE_KPI_OrderOmzetPerKM',
            label: 'Omzet / KM'
          },
          {
            value: 'vE_KPI_OrderOmzetPerKMFull',
            label: 'Omzet / beladen KM'
          },
          {
            value: 'vE_KPI_OrderBelading',
            label: '% Beladen'
          },
          {
            value: 'vE_OrderNormTurnoverKM',
            label: 'Norm Omzet / KM'
          },
          {
            value: 'vE_OrderNormTurnoverKMFull',
            label: 'Norm Omzet / beladen KM'
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            value: 'vE_OrderUren',
            label: 'Totaal (Σ)',
          },
          {
            value: 'vE_OrderUrenLoading',
            label: 'Laden (Σ)',
          },
          {
            value: 'vE_OrderUrenUnloading',
            label: 'Lossen (Σ)',
          },
          {
            value: 'vE_OrderUrenDriving',
            label: 'Rijden (Σ)',
          },
          {
            value: 'vE_OrderUrenOther',
            label: 'Overig (Σ)',
          },
          {
            value: 'vE_OrderUrenAvg',
            label: 'Totaal (avg)',
          },
          {
            value: 'vE_OrderUrenAvgLoading',
            label: 'Laden (avg)',
          },
          {
            value: 'vE_OrderUrenAvgUnloading',
            label: 'Lossen (avg)',
          },
          {
            value: 'vE_OrderUrenAvgDriving',
            label: 'Rijden (avg)',
          },
          {
            value: 'vE_OrderUrenAvgOther',
            label: 'Overig (avg)',      // $(wActive(Order, 'M_Uren_Rijden_A'))
          }
        ]
      },
      {
        value: 'vergelijkperiode',
        label: 'Voorgaand jaar',
        children: [
          {
            value: 'vE_OrderOmzetVorigJaar',
            label: 'Omzet',
          },
          {
            value: 'vE_OrderAantalOrdersVorigJaar',
            label: 'Order',
          },
          {
            value: 'vE_OrderAantalRittenVorigJaar',
            label: 'Ritten',
          },
          {
            value: 'vE_KPI_OrderGoedkeurVorigJaar',
            label: 'OK'
          },

          {
            value: 'vE_KPI_OrderNormMarginPercentVorigJaar',
            label: 'Norm Marge %'
          },
          {
            value: 'vE_KPI_OrderNormMarginOverOmzetVorigJaar',
            label: 'Norm Marge'
          },
          {
            value: 'vE_KPI_OrderOmzetPerUurVorigJaar',
            label: 'Omzet / Uur'
          },
          {
            value: 'vE_KPI_OrderOmzetPerUurCombinatieVolgendeOrderVorigJaar',
            label: 'Omzet / Uur - rondrit'
          },
          {
            value: 'vE_KPI_OrderTripOutsourcedVorigJaar',
            label: '% Uitbesteed'
          },
          {
            value: 'vE_KPI_OrderCharterMargeVorigJaar',
            label: 'Derden Marge'
          },

        ],
      },
      {
        value: 'persize',
        label: 'Per Eenheid',
        children: [
          {
            value: 'lm',
            label: 'LM',
            children: [
              {
                value: 'vE_OrderLM',
                label: 'Totaal',
                help: 'Het totaal aantal laadmeters vanuit Transpas'
              },
              {
                value: 'vE_OrderLMAvg',
                label: 'Gemiddelde',
                help: 'Het gemiddelde aantal laadmeters per zending vanuit Transpas',
              },
            ]
          },
          {
            value: 'kg',
            label: 'KG',
            children: [
              {
                value: 'vE_OrderKG',
                label: 'Totaal',
                help: 'Het totaal aantal kilo\'s vanuit Transpas'

              },
              {
                value: 'vE_OrderKGAvg',
                label: 'Gemiddelde',
                help: 'Het gemiddelde aantal kilo\'s vanuit Transpas'

              }
            ]
          },
          {
            value: 'pp',
            label: 'PP',
            children: [
              {
                value: 'vE_OrderPP',
                label: 'Totaal',
                help: 'Het totaal aantal PP vanuit Transpas'

              },
              {
                value: 'vE_OrderPPAvg',
                label: 'Gemiddelde',
                help: 'Het gemiddelde aantal PP vanuit Transpas'

              }
            ]
          },
          {
            value: 'zendinggrootte',
            label: 'Zendinggrootte',
            children: [
              {
                value: 'vE_OrderShipmentSize',
                label: 'Totaal',
                help: 'Het totaal berekende LM. Waarbij M3 / KG / PP is omgerekend naar LM.'
              },
              {
                value: 'vE_KPI_OrderOmzetPerZendingGroottePervE_OrderShipmentSize',
                label: 'Omzet / LM',
                help: 'De totale omzet / gedeeld door het totale aantal berekende LM.'
              },
              {
                value: 'vE_KPI_OrderLaadMinutenPerZendingGrootte',
                label: 'Laden / LM',
                help: 'Het aantal laadminuten per berekende laadmeter. Hierbij worden enkel de goedgekeurde orders meegenomen.'
              },
              {
                value: 'vE_KPI_OrderLosMinutenPerZendingGrootte',
                label: 'Lossen / LM',
                help: 'Het aantal losminuten per berekende laadmeter. Hierbij worden enkel de goedgekeurde orders meegenomen.'
              },
              {
                value: 'vE_KPI_OrderMinutenPerZendingGrootte',
                label: 'Tijd / LM',
                help: 'Het aantal minuten per berekende laadmeter. Hierbij worden enkel de goedgekeurde orders meegenomen.'
              },
            ]
          },
          

          //{
          //  value: 'vE_OrderShipmentSize',
          //  label: 'LM'
          //},
          
        ]
      },
    ]
  }
];
