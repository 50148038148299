export default [
    {
      value: 'dimensions',
      label: 'Dimensies',
      class: 'bg-primary',
      children: [
        {
          label: 'Debiteur',
          value: 'debiteur',
          children: [
            {
              label: 'Naam',
              value: 'vD_Order.Debtor.Name'
            },
            {
              label: 'Nummer',
              value: 'vD_Order.Debtor.Number'
            }
          ]
        },
        {
          label: 'Order',
          value: 'order',
          children: [
            {
              value: 'vD_Order.Dossier',
              label: 'Quotation'
            },
            {
              value: 'vD_Order.OrderNumberDisplay',
              label: 'Zending'
            },
          ]
        },
        {
          label: 'Laadlocatie',
          value: 'laadlocatie',
          children: [
            {
              value: 'vD_Order.LoadingLocation.Name',
              label: 'Naam'
            },
            {
              value: 'vD_Order.LoadingLocation.Adress',
              label: 'Adres'
            },
            {
              value: 'vD_Order.LoadingLocation.SearchCode',
              label: 'Zoekcode'
            },
            {
              value: 'vD_Order.LoadingLocation.ZIPcode',
              label: 'Postcode'
            },
            {
              value: 'vD_Order.LoadingLocation.City',
              label: 'Plaats'
            },
            {
              value: 'vD_Order.LoadingLocation.District',
              label: 'Gemeente'
            },
            {
              value: 'vD_Order.LoadingLocation.Province',
              label: 'Provincie'
            },
            {
              value: 'vD_Order.LoadingLocation.Country',
              label: 'Land'
            },
            {
              value: 'vD_Order.LoadingLocation.Land',
              label: 'Land (ISO)'
            },
          ]
        },
        {
          label: 'Loslocatie',
          value: 'loslocatie',
          children: [
            {
              value: 'vD_Order.UnloadingLocation.Name',
              label: 'Naam'
            },
            {
              value: 'vD_Order.UnloadingLocation.Adress',
              label: 'Adres'
            },
            {
              value: 'vD_Order.UnloadingLocation.SearchCode',
              label: 'Zoekcode'
            },
            {
              value: 'vD_Order.UnloadingLocation.ZIPcode',
              label: 'Postcode'
            },
            {
              value: 'vD_Order.UnloadingLocation.City',
              label: 'Plaats'
            },
            {
              value: 'vD_Order.UnloadingLocation.District',
              label: 'Gemeente'
            },
            {
              value: 'vD_Order.UnloadingLocation.Province',
              label: 'Provincie'
            },
            {
              value: 'vD_Order.UnloadingLocation.Country',
              label: 'Land'
            },
            {
              value: 'vD_Order.UnloadingLocation.Land',
              label: 'Land (ISO)'
            },
          ]
        },
        {
          label: 'Periode',
          value: 'periode',
          children: [
            {
              value: 'vD_Year',
              label: 'Jaar'
            },
            {
              value: 'vD_Quarter',
              label: 'Kwartaal'
            },
            {
              value: 'vD_Month',
              label: 'Maand'
            },
            {
              value: 'vD_Period',
              label: 'Periode'
            },
            {
              value: 'vD_Week',
              label: 'Week'
            },
            {
              value: 'vD_Date',
              label: 'Datum'
            },
            {
              value: 'vD_Weekday',
              label: 'Dag'
            },
          ]
        }
      ]
    },
    {
      label: 'Meetwaarden',
      value: 'measures',
      class: 'bg-danger',
      children: [
        {
          value: 'omzet',
          label: 'Omzet',
          children: [
            {
              value: 'vE_OrderOmzet',
              label: 'Totaal'
            },
            {
              value: 'vE_OrderOmzet.Vracht',
              label: 'Vracht',
              help: 'Vrachtkosten vanuit Transpas'
            },
            {
              value: 'vE_OrderOmzet.Fuel',
              label: 'Brandstof',
              help: 'Brandstoftoeslagen zoals deze berekend worden op zendingniveau in Transpas'
            },
            {
              value: 'vE_OrderOmzet.ExtraKosten',
              label: 'Extra Kosten',
              help: 'Opbrengsten beschikbaar in Transpas onder het menuitem \'Extra Kosten\' '
            },
          ]
        },
        {
          value: 'kosten',
          label: 'Kosten',
          children: [
            {
              value: 'vE_OrderKosten',
              label: 'Totaal',
              help: 'Totaal van de directe kosten.'
            },
            {
              value: 'vE_OrderKostenCharter',
              label: 'Charter', 
              help: 'Kosten van kilometer, uren en vaste charters'
            },
            {
              value: 'vE_OrderKostenTol',
              label: 'Tol',
              help: 'Tolkosten worden enkel vanuit het TMS meegenomen wanneer ze geregistreerd worden op een eigen auto'
            },
            {
              value: 'vE_OrderKostenWarehouse',
              label: 'Warehouse',
              help: 'Voorcalculatorische kosten vanuit het TMS voor de reservering van de directe Warehouse kosten per zending'
            },
            {
               value: 'vE_OrderKostenOther',
               label: 'Other',
               help: 'Alle kosten die niet in één van bovenstaande categoriën ingedeeld zijn vallen hieronder'
            },
          ]
        },
        {
          value: 'aantal',
          label: 'Aantal',
          children: [
            {
              value: 'vE_OrderAantalOrders',
              label: 'Zendingen',
              help: 'Het aantal zendingen, waarbij een zending een unieke transportopdracht is van A naar B'
            },
            {
              value: 'vE_OrderAantalRitten',
              label: 'Ritten',
              help: 'Het aantal ritten behorend bij de geselecteerde zendingen. Hier zien we altijd het totaal. '
            },
          ]
        },
        {
          value: 'kpi',
          label: 'KPI',
          children: [
            {
              value: 'vE_KPI_OrderGoedkeur',
              label: 'OK',
              help: 'Het percentage zendingen dat goedgekeurd is. Wanneer er iets misgaat met een zending wordt deze niet meegenomen in de kerngetallen.'
            },
  
            {
              value: 'vE_KPI_OrderNormMarginPercent',
              label: 'Norm Marge %',
              help: 'De Norm Marge combineert de charter marge en de omzet/uur om deze samen te toetsen tegen de gestelde normen. Voor het eigen werk wordt gekeken hoeveel de omzet / uur afwijkt van de norm voor de omzet/uur. Dit wordt gecombineerd met de afwijking van de chartermarge ten opzichte van de norm voor de chartermarge. Dit wordt enkel uitgerekend voor goedgekeurde orders.'
            },
            {
              value: 'vE_KPI_OrderNormMarginOverOmzet',
              label: 'Norm Marge',
              help: 'De Norm Marge interpoleert het berekende percentage over de gehele omzet.'
            },
          ]
        },
        {
          value: 'eigenwagen',
          label: 'Eigen Wagen',
          children: [
            {
              value: 'vE_KPI_OrderOmzetPerUur',
              label: 'Omzet / Uur', 
              help: 'De omzet / uur is de omzet van het eigen werk minus de directe-kosten (Tol, X-dock) van het eigen werk afgezet tegen het aantal ingezette uren. Het gaat hier altijd enkel om goedgekeurde orders. '
            },
            {
              value: 'vE_KPI_Order_TripOmzetPerUur',
              label: 'Omzet / Uur - rit',
              help: 'De omzet / uur van de gehele rit(ten) waar deze order(s) ingezeten heeft/hebben. Het gaat hier altijd enkel om goedgekeurde ritten. '
            },
            {
              value: 'vE_OrderNormTurnoverHour',
              label: 'Norm Omzet / Uur',
              help: 'De geconfigureerde norm op orderniveau  voor het eigen werk. De omzet per uur wordt getoetst aan deze norm. '

            },
          ]
        },
        {
          value: 'uitbesteed',
          label: 'Uitbesteed',
          children: [
            {
              value: 'vE_KPI_OrderTripOutsourced',
              label: '% Uitbesteed',
              help: 'Het percentage dat is uitbesteed. Indien een zending in het voorhaaltraject door een eigen wagen uitgevoerd wordt en het wegbrengen wordt uitbesteed is deze zending voor 50% uitbesteed.'
            },
            {
              value: 'vE_KPI_OrderCharterMarge',
              label: 'Derden Marge',
              help: 'De marge op het uitbesteede werk. Dit is de omzet minus de kosten. Hierbij wordt enkel de omzet en de kosten meegenomen indien de order goedgekeurd is. '
            },
            {
              value: 'vE_OrderNormCharter',
              label: 'Norm',
              help: 'De geconfigureerde norm op orderniveau  voor het uitbesteede werk De Derden Marge wordt getoetst aan deze norm. '
            },
          ]
        },
        {
          value: 'potency',
          label: 'Potentie',
          children: [
            {
              value: 'vE_OrderPotency',
              label: 'Totaal'
            },
            {
              value: 'vE_OrderPotencyCommercial',
              label: 'Commercieel'
            },
            {
              value: 'vE_OrderPotencyOperational',
              label: 'Operationeel'
            },
          ]
        },
        {
          value: 'km',
          label: 'KM',
          children: [
            {
              value: 'vE_OrderKM',
              label: 'Totaal',
              help: 'Geeft de totale kilometers op orderniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'vE_KPI_OrderOmzetPerKM',
              label: 'Omzet / KM',
              help: 'Geeft de omzet per kilometer weer. Hierbij wordt enkel de goedgekeurde omzet afgezet tegen de goedgekeurde kilometers'
            },
            {
              value: 'vE_OrderNormTurnoverKM',
              label: 'Norm Omzet / KM',
              help: 'De geconfigureerde norm op orderniveau voor de Omzet / KM.'
            }
          ]
        },
        {
          value: 'uren',
          label: 'Uren',
          children: [
            {
              value: 'vE_OrderUren',
              label: 'Totaal (Σ)',
              help: 'Het totaal aantal uren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'vE_OrderUrenLoading',
              label: 'Laden (Σ)',
              help: 'Het totaal aantal laaduren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'vE_OrderUrenUnloading',
              label: 'Lossen (Σ)',
              help: 'Het totaal aantal losuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

            },
            {
              value: 'vE_OrderUrenDriving',
              label: 'Rijden (Σ)',
              help: 'Het totaal aantal rijuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

            },
            {
              value: 'vE_OrderUrenOther',
              label: 'Overig (Σ)',
              help: 'Het totaal aantal overige uren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'vE_OrderUrenAvg',
              label: 'Totaal (avg)',
              help: 'Het gemiddeld aantal uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'vE_OrderUrenAvgLoading',
              label: 'Laden (avg)',
              help: 'Het gemiddeld aantal laaduren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'vE_OrderUrenAvgUnloading',
              label: 'Lossen (avg)',
              help: 'Het gemiddeld aantal losuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'vE_OrderUrenAvgDriving',
              label: 'Rijden (avg)',
              help: 'Het gemiddeld aantal rijuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'vE_OrderUrenAvgOther',
              label: 'Overig (avg)',
              help: 'Het gemiddeld aantal overige uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            }
          ]
        },
        {
          value: 'persize',
          label: 'Per Eenheid',
          children: [
            {
              value: 'kg',
              label: 'KG',
              children: [
                {
                  value: 'vE_OrderKG',
                  label: 'Totaal',
                  help: 'Het totaal aantal kilo\'s vanuit Transpas'

                },
                {
                  value: 'vE_OrderKGAvg',
                  label: 'Gemiddelde',
                  help: 'Het gemiddelde aantal kilo\'s vanuit Transpas'

                }
              ]
            },
            {
              value: 'zendinggrootte',
              label: 'Zendinggrootte',
              children: [
                {
                  value: 'vE_OrderShipmentSize',
                  label: 'Totaal',
                  help: 'Het totaal berekende gewicht. Waarbij M3 / KG / PP is omgerekend naar gewicht.'
                },
                {
                  value: 'vE_KPI_OrderOmzetPerZendingGroottePervE_OrderShipmentSize',
                  label: 'Omzet / KG',
                  help: 'De totale omzet / gedeeld door het totale aantal berekende gewicht.'
                },
                {
                  value: 'vE_KPI_OrderLaadMinutenPerZendingGrootte',
                  label: 'Laden / KG',
                  help: 'Het aantal laadminuten per berekende kg. Hierbij worden enkel de goedgekeurde orders meegenomen.'
                },
                {
                  value: 'vE_KPI_OrderLosMinutenPerZendingGrootte',
                  label: 'Lossen / KG',
                  help: 'Het aantal losminuten per berekende kg. Hierbij worden enkel de goedgekeurde orders meegenomen.'
                },
                {
                  value: 'vE_KPI_OrderMinutenPerZendingGrootte',
                  label: 'Tijd / KG',
                  help: 'Het aantal minuten per berekende kg. Hierbij worden enkel de goedgekeurde orders meegenomen.'
                },
              ]
            },
          ]
        },
      ]
    }
  ];
  