export default [
    {
      value: 'dimensions',
      label: 'Dimensies',
      class: 'bg-primary',
      children: [
        {
          label: 'Debiteur',
          value: 'debiteur',
          children: [
            {
              label: 'Naam',
              value: 'vD_Order.Debtor.Name'
            },
            {
              label: 'Nummer',
              value: 'vD_Order.Debtor.Number'
            }
          ]
        },
        {
          label: 'Order',
          value: 'order',
          children: [
            {
              value: 'vD_Order.OrderNumber',
              label: 'Technisch %Order%'
            },
            {
              value: 'vD_Order.OrderNumberDisplay',
              label: 'Zending'
            },
            {
              value: 'vD_Order.KindDescription',
              label: 'Status'
            }

          ]
        },
        {
          label: 'Laad Locatie',
          value: 'laadlocatie',
          children: [
            {
              value: 'vD_Order.LoadingLocation.ID',
              label: 'ID'
            },
            {
              value: 'vD_Order.LoadingLocation.Name',
              label: 'Naam'
            },
            {
              value: 'vD_Order.LoadingLocation.Adress',
              label: 'Adres'
            },
            {
              value: 'vD_Order.LoadingLocation.ZIPcode',
              label: 'Postcode'
            },
            {
              value: 'vD_Order.LoadingLocation.City',
              label: 'Plaats'
            },
            {
              value: 'vD_Order.LoadingLocation.Country',
              label: 'Land'
            },
          ]
        },
        {
          label: 'Los Locatie',
          value: 'loslocatie',
          children: [
            {
              value: 'vD_Order.UnloadingLocation.ID',
              label: 'ID'
            },
            {
              value: 'vD_Order.UnloadingLocation.Name',
              label: 'Naam'
            },
            {
              value: 'vD_Order.UnloadingLocation.Adress',
              label: 'Adres'
            },
            {
              value: 'vD_Order.UnloadingLocation.ZIPcode',
              label: 'Postcode'
            },
            {
              value: 'vD_Order.UnloadingLocation.City',
              label: 'Plaats'
            },
            {
              value: 'vD_Order.UnloadingLocation.Country',
              label: 'Land'
            }
          ]
        },
        {
          label: 'Periode',
          value: 'periode',
          children: [
            {
              value: 'vD_Year',
              label: 'Jaar'
            },
            {
              value: 'vD_Quarter',
              label: 'Kwartaal'
            },
            {
              value: 'vD_Month',
              label: 'Maand'
            },
            {
              value: 'vD_Period',
              label: 'Periode'
            },
            {
              value: 'vD_Week',
              label: 'Week'
            },
            {
              value: 'vD_Date',
              label: 'Datum'
            }
          ]
        }
      ]
    },
    {
      label: 'Meetwaarden',
      value: 'measures',
      class: 'bg-danger',
      children: [
        {
          value: 'omzet',
          label: 'Omzet',
          children: [
            {
              value: 'vE_OrderOmzet',
              label: 'Totaal'
            },
            {
              value: 'vE_OrderOmzet.Vracht',
              label: 'Vracht'
            },
            {
              value: 'vE_OrderOmzet.Fuel',
              label: 'Brandstof'
            },
            {
              value: 'vE_OrderOmzet.ExtraKosten',
              label: 'Extra Kosten'
            },
          ]
        },
        {
          value: 'kosten',
          label: 'Kosten',
          children: [
            {
              value: 'vE_OrderKosten',
              label: 'Totaal',
            },
            {
              value: 'vE_OrderKostenCharter',
              label: 'Charter',
            },
            {
              value: 'vE_OrderKostenTol',
              label: 'Tol',
            },
            {
              value: 'vE_OrderKostenWarehouse',
              label: 'Warehouse',
            },
            {
               value: 'vE_OrderKostenOther',
               label: 'Other',
            },
          ]
        },
        {
          value: 'aantal',
          label: 'Aantal',
          children: [
            {
              value: 'vE_OrderAantalDossiers',
              label: 'Dossiers',
            },
            {
              value: 'vE_OrderAantalOrders',
              label: 'Zendingen',
            },
            {
              value: 'vE_OrderAantalRitten',
              label: 'Ritten',
            },
          ]
        },
        {
          value: 'kpi',
          label: 'KPI',
          children: [
            {
              value: 'vE_KPI_OrderGoedkeur',
              label: 'OK'
            },
  
            {
              value: 'vE_KPI_OrderNormMarginPercent',
              label: 'Norm Marge %'
            },
            {
              value: 'vE_KPI_OrderNormMarginOverOmzet',
              label: 'Norm Marge'
            },
  
  
          ]
        },
        {
          value: 'eigenwagen',
          label: 'Eigen Wagen',
          children: [
            {
              value: 'vE_KPI_OrderOmzetPerUur',
              label: 'Omzet / Uur'
            },
            {
              value: 'vE_KPI_Order_TripOmzetPerUur',
              label: 'Omzet / Uur - rit'
            },
            {
              value: 'vE_OrderNormTurnoverHour',
              label: 'Norm Omzet / Uur'
            },
          ]
        },
        {
          value: 'uitbesteed',
          label: 'Uitbesteed',
          children: [
            {
              value: 'vE_KPI_OrderTripOutsourced',
              label: '% Uitbesteed'
            },
            {
              value: 'vE_KPI_OrderCharterMarge',
              label: 'Derden Marge'
            },
            {
              value: 'vE_OrderNormCharter',
              label: 'Norm'
            },
          ]
        },
        {
          value: 'km',
          label: 'KM',
          children: [
            {
              value: 'vE_OrderKM',
              label: 'Totaal'
            },
            {
              value: 'vE_KPI_OrderOmzetPerKM',
              label: 'Omzet / KM'
            },
            {
              value: 'vE_OrderNormTurnoverKM',
              label: 'Norm Omzet / KM'
            }
          ]
        },
        {
          value: 'uren',
          label: 'Uren',
          children: [
            {
              value: 'vE_OrderUren',
              label: 'Totaal (Σ)',
            },
            {
              value: 'vE_OrderUrenLoading',
              label: 'Laden (Σ)',
            },
            {
              value: 'vE_OrderUrenUnloading',
              label: 'Lossen (Σ)',
            },
            {
              value: 'vE_OrderUrenDriving',
              label: 'Rijden (Σ)',
            },
            {
              value: 'vE_OrderUrenOther',
              label: 'Overig (Σ)',
            },
            {
              value: 'vE_OrderUrenAvg',
              label: 'Totaal (avg)',
            },
            {
              value: 'vE_OrderUrenAvgLoading',
              label: 'Laden (avg)',
            },
            {
              value: 'vE_OrderUrenAvgUnloading',
              label: 'Lossen (avg)',
            },
            {
              value: 'vE_OrderUrenAvgDriving',
              label: 'Rijden (avg)',
            },
            {
              value: 'vE_OrderUrenAvgOther',
              label: 'Overig (avg)',
            }
          ]
        },
        {
          value: 'persize',
          label: 'Per Eenheid',
          children: [
            {
              value: 'vE_OrderShipmentSize',
              label: 'LM'
            },
            {
              value: 'vE_KPI_OrderOmzetPerZendingGroottePervE_OrderShipmentSize',
              label: 'Omzet / LM'
            },
            {
              value: 'vE_KPI_OrderLaadMinutenPerZendingGrootte',
              label: 'Laden / LM'
            },
            {
              value: 'vE_KPI_OrderLosMinutenPerZendingGrootte',
              label: 'Lossen / LM'
            },
            {
              value: 'vE_KPI_OrderMinutenPerZendingGrootte',
              label: 'Tijd / LM'
            },
          ]
        },
      ]
    }
  ];
  