import qlik from '../orders/qlik';
import {dimensions, measures} from './qlik/qlik';
export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Chauffeur',
        value: 'driver',
        children: [
          {
            label: 'Naam',
            value: 'dimensions.driver.name',
            qlik: dimensions.driver.name
          },
          {
            label: 'Nummer',
            value: 'dimensions.driver.number',
            qlik: dimensions.driver.number
          }
        ]
      },
      {
        label: 'Auto',
        value: 'vehicle',
        children: [
          {
            label: 'Nummer',
            value: 'dimensions.vehicle.number',
            qlik: dimensions.vehicle.number
          },
          {
            label: 'Naam',
            value: 'dimensions.vehicle.name',
            qlik: dimensions.vehicle.name
          },
          {
            label: 'Type',
            value: 'dimensions.vehicle.type',
            qlik: dimensions.vehicle.type
          },
        ]
      },
      {
        label: 'Crediteur',
        value: 'crediteur',
        children: [
          {
            label: 'Nummer',
            value: 'dimensions.charter.number',
            qlik: dimensions.charter.number
          },
          {
            label: 'Naam',
            value: 'dimensions.charter.name',
            qlik: dimensions.charter.name
          },
          {
            label: 'Soort', 
            value: 'dimensions.charter.kinddescription',
            qlik: dimensions.charter.kinddescription
          },
          {
            label: 'Zoekcode', 
            value: 'dimensions.charter.searchcode',
            qlik: dimensions.charter.searchcode
          }
          
        ]
      },
      {
        label: 'Trailer',
        value: 'trailer',
        children: [
          {
            label: 'Nummer',
            value: 'dimensions.trailer.number',
            qlik: dimensions.trailer.number
          },
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            value: 'dimensions.trip.number',
            label: 'Rit Nummer',
            qlik: dimensions.trip.number
          },
          {
            value: 'dimensions.trip.resourcecombination',
            label: 'Resource Combination',
            qlik: dimensions.trip.resourcecombination
          },
          {
            value: 'dimensions.trip.plangroup',
            label: 'Plangroep',
            qlik: dimensions.trip.plangroup
          }
        ]
      },
      {
        label: 'Start Locatie',
        value: 'laadlocatie',
        children: [
          {
            value: 'dimensions.startlocation.name',
            label: 'Naam',
            qlik: dimensions.startlocation.name
          },
          {
            value: 'dimensions.startlocation.adress',
            label: 'Adres',
            qlik: dimensions.startlocation.adress
          },
          {
            value: 'dimensions.startlocation.searchcode',
            label: 'Zoekcode',
            qlik: dimensions.startlocation.searchcode
          },
          {
            value: 'dimensions.startlocation.zipcode',
            label: 'Postcode',
            qlik: dimensions.startlocation.zipcode
          },
          {
            value: 'dimensions.startlocation.city',
            label: 'Plaats',
            qlik: dimensions.startlocation.city
          },
          {
            value: 'dimensions.startlocation.district',
            label: 'Gemeente',
            qlik: dimensions.startlocation.district
          },
          {
            value: 'dimensions.startlocation.province',
            label: 'Provincie',
            qlik: dimensions.startlocation.province
          },
          {
            value: 'dimensions.startlocation.country',
            label: 'Land',
            qlik: dimensions.startlocation.country
          },
          {
            value: 'dimensions.startlocation.land',
            label: 'Land (ISO)',
            qlik: dimensions.startlocation.land
          }
        ]
      },
      {
        label: 'Eind Locatie',
        value: 'loslocatie',
        children: [
          {
            value: 'dimensions.endlocation.name',
            label: 'Name',
            qlik: dimensions.endlocation.name
          },
          {
            value: 'dimensions.endlocation.adress',
            label: 'Adres',
            qlik: dimensions.endlocation.adress
          },
          {
            value: 'dimensions.endlocation.searchcode',
            label: 'Zoekcode',
            qlik: dimensions.endlocation.searchcode
          },
          {
            value: 'dimensions.endlocation.zipcode',
            label: 'Postcode',
            qlik: dimensions.endlocation.zipcode
          },
          {
            value: 'dimensions.endlocation.city',
            label: 'Plaats',
            qlik: dimensions.endlocation.city
          },
          {
            value: 'dimensions.endlocation.city',
            label: 'Gemeente',
            qlik: dimensions.endlocation.city
          },
          {
            value: 'dimensions.endlocation.province',
            label: 'Provincie',
            qlik: dimensions.endlocation.province
          },
          {
            value: 'dimensions.endlocation.country',
            label: 'Land',
            qlik: dimensions.endlocation.country
          },
          {
            value: 'dimensions.endlocation.land',
            label: 'Land (ISO)',
            qlik: dimensions.endlocation.land
          },
        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            value: 'dimensions.period.year',
            label: 'Jaar',
            qlik: dimensions.period.year
          },
          {
            value: 'dimensions.period.quarter',
            label: 'Kwartaal',
            qlik: dimensions.period.quarter
          },
          {
            value: 'dimensions.period.month',
            label: 'Maand',
            qlik: dimensions.period.month
          },
          {
            value: 'dimensions.period.period',
            label: 'Periode',
            qlik: dimensions.period.period
          },
          {
            value: 'dimensions.period.week',
            label: 'Week',
            qlik: dimensions.period.week
          },
          {
            value: 'dimensions.period.date',
            label: 'Datum',
            qlik: dimensions.period.date
          }
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            value: 'measures.omzet.totaal',
            label: 'Totaal',
            qlik: measures.omzet.totaal
          },
          {
            value: 'measures.omzet.freight',
            label: 'Vracht',
            qlik: measures.omzet.freight
          },
          {
            value: 'measures.omzet.fuel',
            label: 'Brandstof',
            qlik: measures.omzet.fuel
          },
          {
            value: 'measures.omzet.extra',
            label: 'Extra Kosten',
            qlik: measures.omzet.extra
          },
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            value: 'measures.kosten.totaal',
            label: 'Totaal',
            qlik: measures.kosten.totaal
          },
          {
            value: 'measures.kosten.charter',
            label: 'Charter',
            qlik: measures.kosten.charter
          },
          {
            value: 'measures.kosten.toll',
            label: 'Tol',
            qlik: measures.kosten.toll
          },
          {
            value: 'measures.kosten.warehouse',
            label: 'Warehouse',
            qlik: measures.kosten.warehouse
          },
          {
            value: 'measures.kosten.boat',
            label: 'Boot',
            qlik: measures.kosten.boat,
            help: 'Boot kosten vanuit Transpas voor de reservering van de boot kosten per zending'
          },
          {
            value: 'measures.kosten.rail',
            label: 'Trein',
            qlik: measures.kosten.rail,
            help: 'Trein kosten vanuit Transpas voor de reservering van de rail kosten per zending'
          },
    
          {
             value: 'measures.kosten.other',
             label: 'Other',
             qlik: measures.kosten.other
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            value: 'measures.aantal.dossiers',
            label: 'Dossiers',
            qlik: measures.aantal.dossiers
          },
          {
            value: 'measures.aantal.orders',
            label: 'Zendingen',
            qlik: measures.aantal.orders
          },
          {
            value: 'measures.aantal.ritten',
            label: 'Ritten',
            qlik: measures.aantal.ritten
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            value: 'measures.kpi.goedkeur',
            label: 'OK',
            qlik: measures.kpi.goedkeur
          },

          {
            value: 'measures.kpi.normmarginpercent',
            label: 'Norm Marge %',
            qlik: measures.kpi.normmarginpercent
          },
          {
            value: 'measures.kpi.normmarginomzet',
            label: 'Norm Marge',
            qlik: measures.kpi.normmarginomzet
          },


        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            value: 'measures.own.omzetperuur',
            label: 'Omzet / Uur',
            qlik: measures.own.omzetperuur
          },
          {
            value: 'measures.own.normomzetperuur',
            label: 'Norm Omzet / Uur',
            qlik: measures.own.normomzetperuur
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            value: 'measures.outsourced.percentage',
            label: '% Uitbesteed',
            qlik: measures.outsourced.percentage
          },
          {
            value: 'measures.outsourced.marge',
            label: 'Derden Marge',
            qlik: measures.outsourced.marge
          },
          {
            value: 'measures.outsourced.norm',
            label: 'Norm',
            qlik: measures.outsourced.norm
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            value: 'measures.km.km',
            label: 'Totaal',
            qlik: measures.km.km
          },
          {
            value: 'measures.km.omzetperkm',
            label: 'Omzet / KM',
            qlik: measures.km.omzetperkm
          },
          {
            value: 'measures.km.normomzetperkm',
            label: 'Norm Omzet / KM',
            qlik: measures.km.normomzetperkm
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            value: 'measures.uren.totaal',
            label: 'Totaal (Σ)',
            qlik: measures.uren.totaal
          },
          {
            value: 'measures.uren.loading',
            label: 'Laden (Σ)',
            qlik: measures.uren.loading
          },
          {
            value: 'measures.uren.unloading',
            label: 'Lossen (Σ)',
            qlik: measures.uren.unloading
          },
          {
            value: 'measures.uren.driving',
            label: 'Rijden (Σ)',
            qlik: measures.uren.driving
          },
          {
            value: 'measures.uren.other',
            label: 'Overig (Σ)',
            qlik: measures.uren.other
          },
          {
            value: 'measures.uren.avg_totaal',
            label: 'Totaal (avg)',
            qlik: measures.uren.avg_totaal
          },
          {
            value: 'measures.uren.avg_loading',
            label: 'Laden (avg)',
            qlik: measures.uren.avg_loading
          },
          {
            value: 'measures.uren.avg_unloading',
            label: 'Lossen (avg)',
            qlik: measures.uren.avg_unloading
          },
          {
            value: 'measures.uren.avg_driving',
            label: 'Rijden (avg)',
            qlik: measures.uren.avg_driving
          },
          {
            value: 'measures.uren.avg_other',
            label: 'Overig (avg)', 
            qlik: measures.uren.avg_other     // $(wActive(Order, 'M_Uren_Rijden_A'))
          }
        ]
      },
      {
        value: 'vergelijkperiode',
        label: 'Voorgaand jaar',
        children: [
          {
            value: 'measures.previousyear.omzet',
            label: 'Omzet',
            qlik: measures.previousyear.omzet
          },
          {
            value: 'measures.previousyear.orders',
            label: 'Order',
            qlik: measures.previousyear.orders
          },
          {
            value: 'measures.previousyear.ritten',
            label: 'Ritten',
            qlik: measures.previousyear.ritten
          },
          {
            value: 'measures.previousyear.goedkeur',
            label: 'OK',
            qlik: measures.previousyear.goedkeur
          },

          {
            value: 'measures.previousyear.normmarge',
            label: 'Norm Marge %',
            qlik: measures.previousyear.normmarge
          },
          {
            value: 'measures.previousyear.normmargeomzet',
            label: 'Norm Marge',
            qlik: measures.previousyear.normmargeomzet
          },
          {
            value: 'measures.previousyear.omzetperuur',
            label: 'Omzet / Uur',
            qlik: measures.previousyear.omzetperuur
          },
          {
            value: 'measures.previousyear.uitbesteed',
            label: '% Uitbesteed',
            qlik: measures.previousyear.uitbesteed
          },
          {
            value: 'measures.previousyear.chargermarge',
            label: 'Derden Marge',
            qlik: measures.previousyear.chargermarge
          },
          {
            value: 'zendinggrootte',
            label: 'Zendinggrootte',
            children: [
          {
            value: 'measures.previousyear.kg',
            label: 'Gemiddeld KG',
            qlik: measures.previousyear.kg
          },
          {
            value: 'measures.previousyear.avg_kg',
            label: 'Totaal KG',
            qlik: measures.previousyear.avg_kg
          },
          {
            value: 'measures.previousyear.avg_lm',
            label: 'Gemiddeld LM',
            qlik: measures.previousyear.avg_lm
          },
          {
            value: 'measures.previousyear.lm',
            label: 'Totaal LM',
            qlik: measures.previousyear.lm
          },
        ],
          },

        ],
      },
    ]
  }
];
