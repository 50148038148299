import qlik from './qlik';
import {dimensions, measures} from './qlik/klant/klant';
export default [
    {
      value: 'dimensions',
      label: 'Dimensies',
      class: 'bg-primary',
      children: [
        {
          label: 'Opdrachtgever',
          value: 'klant',
          children: [
            {
              value: "dimensions.customer.name",
              label: "Naam",
              qlik: dimensions.customer.name
            },
            {
              value: "dimensions.customer.number",
              label: 'Nummer',
              qlik: dimensions.customer.number
            }
          ]
        },
        {
          label: 'Debiteur',
          value: 'debiteur',
          children: [
            {
              value: "dimensions.debtor.name",
              label: 'Naam',
              qlik: dimensions.debtor.name,
            },
            {
              value: "dimensions.debtor.number",
              label: 'Nummer',
              qlik: dimensions.debtor.number
            }
          ]
        },
        {
          label: 'Order',
          value: 'order',
          children: [
            {
              value: 'dimensions.order.number',
              label: 'Technisch %Order%',
              qlik: dimensions.order.number
            },
            {
              value: 'dimensions.order.dossier',
              label: 'Dossier',
              qlik: dimensions.order.dossier
            },
            {
              value: 'dimensions.order.ordernumberdisplay',
              label: 'Zending',
              qlik: dimensions.order.ordernumberdisplay
            },
            {
              value: 'dimensions.order.reference',
              label: 'Reference',
              qlik: dimensions.order.reference
            },
            {
              value: 'dimensions.order.plangroup',
              label: 'Plangroep',
              qlik: dimensions.order.plangroup
            },
            {
              value: 'dimensions.order.status',
              label: 'Status',
              qlik: dimensions.order.status
            },

          ]
        },
        {
          label: 'Laadlocatie',
          value: 'laadlocatie',
          children: [
            {
              value: 'dimensions.loadinglocation.name',
              label: 'Naam',
              qlik: dimensions.loadinglocation.name
            },
            {
              value: 'dimensions.loadinglocation.adress',
              label: 'Adres',
              qlik: dimensions.loadinglocation.adress
            },
            {
              value: 'dimensions.loadinglocation.searchcode',
              label: 'Zoekcode',
              qlik: dimensions.loadinglocation.searchcode
            },
            {
              value: 'dimensions.loadinglocation.zipcode',
              label: 'Postcode',
              qlik: dimensions.loadinglocation.zipcode
            },
            {
              value: 'dimensions.loadinglocation.city',
              label: 'Plaats',
              qlik: dimensions.loadinglocation.city
            },
            {
              value: 'dimensions.loadinglocation.district',
              label: 'Gemeente',
              qlik: dimensions.loadinglocation.district
            },
            {
              value: 'dimensions.loadinglocation.province',
              label: 'Provincie',
              qlik: dimensions.loadinglocation.province
            },
            {
              value: 'dimensions.loadinglocation.country',
              label: 'Land',
              qlik: dimensions.loadinglocation.country
            },
            {
              value: 'dimensions.loadinglocation.land',
              label: 'Land (ISO)',
              qlik: dimensions.loadinglocation.land
            },
          ]
        },
        {
          label: 'Loslocatie',
          value: 'loslocatie',
          children: [
            {
              value: 'dimensions.unloadinglocation.name',
              label: 'Naam',
              qlik: dimensions.unloadinglocation.name
            },
            {
              value: 'dimensions.unloadinglocation.adress',
              label: 'Adres',
              qlik: dimensions.unloadinglocation.adress
            },
            {
              value: 'dimensions.unloadinglocation.searchcode',
              label: 'Zoekcode',
              qlik: dimensions.unloadinglocation.searchcode
            },
            {
              value: 'dimensions.unloadinglocation.zipcode',
              label: 'Postcode',
              qlik: dimensions.unloadinglocation.zipcode
            },
            {
              value: 'dimensions.unloadinglocation.city',
              label: 'Plaats',
              qlik: dimensions.unloadinglocation.city
            },
            {
              value: 'dimensions.unloadinglocation.district',
              label: 'Gemeente',
              qlik: dimensions.unloadinglocation.district
            },
            {
              value: 'dimensions.unloadinglocation.province',
              label: 'Provincie',
              qlik: dimensions.unloadinglocation.province
            },
            {
              value: 'dimensions.unloadinglocation.country',
              label: 'Land',
              qlik: dimensions.unloadinglocation.country
            },
            {
              value: 'dimensions.unloadinglocation.land',
              label: 'Land (ISO)',
              qlik: dimensions.unloadinglocation.land
            },
          ]
        },
        {
          label: 'Periode',
          value: 'periode',
          children: [
            {
              value: 'dimensions.period.year',
              label: 'Jaar',
              qlik: dimensions.period.year
            },
            {
              value: 'dimensions.period.quarter',
              label: 'Kwartaal',
              qlik: dimensions.period.quarter
            },
            {
              value: 'dimensions.period.month',
              label: 'Maand',
              qlik: dimensions.period.month
            },
            {
              value: 'dimensions.period.period',
              label: 'Periode',
              qlik: dimensions.period.period
            },
            {
              value: 'dimensions.period.week',
              label: 'Week',
              qlik: dimensions.period.week
            },
            {
              value: 'dimensions.period.date',
              label: 'Datum',
              qlik: dimensions.period.date
            }
          ]
        }
      ]
    },
    {
      label: 'Meetwaarden',
      value: 'measures',
      class: 'bg-danger',
      children: [
        {
          value: 'omzet',
          label: 'Omzet',
          children: [
            {
              value: 'measures.omzet.totaal',
              label: 'Totaal',
              qlik: measures.omzet.totaal

            },
            {
              value: 'measures.omzet.freight',
              label: 'Vracht',
              help: 'Vrachtkosten vanuit Transpas',
              qlik: measures.omzet.freight
            },
            {
              value: 'measures.omzet.fuel',
              label: 'Brandstof',
              help: 'Brandstoftoeslagen zoals deze berekend worden op zendingniveau in Transpas',
              qlik: measures.omzet.fuel
            },
            {
              value: 'measures.omzet.extra',
              label: 'Extra Kosten',
              qlik: measures.omzet.extra,
              help: 'Opbrengsten beschikbaar in Transpas onder het menuitem \'Extra Kosten\' '
            },
          ]
        },
        {
          value: 'kosten',
          label: 'Kosten',
          children: [
            {
              value: 'measures.kosten.totaal',
              label: 'Totaal',
              qlik: measures.kosten.totaal,
              help: 'Totaal van de directe kosten.'
            },
            {
              value: 'measures.kosten.charter',
              label: 'Charter', 
              qlik: measures.kosten.charter,
              help: 'Kosten van kilometer, uren en vaste charters'
            },
            {
              value: 'measures.kosten.toll',
              label: 'Tol',
              qlik: measures.kosten.toll,
              help: 'Tolkosten worden enkel vanuit Transpas meegenomen wanneer ze geregistreerd worden op een eigen auto'
            },
            {
              value: 'measures.kosten.warehouse',
              label: 'Warehouse',
              qlik: measures.kosten.warehouse,
              help: 'Voorcalculatorische kosten vanuit Transpas voor de reservering van de directe Warehouse kosten per zending'
            },
            {
              value: 'measures.kosten.boat',
              label: 'Boot',
              qlik: measures.kosten.boat,
              help: 'Boot kosten vanuit Transpas voor de reservering van de boot kosten per zending'
            },
            {
              value: 'measures.kosten.rail',
              label: 'Trein',
              qlik: measures.kosten.rail,
              help: 'Trein kosten vanuit Transpas voor de reservering van de rail kosten per zending'
            },
            {
               value: 'measures.kosten.other',
               label: 'Other',
               qlik: measures.kosten.other,
               help: 'Alle kosten die niet in één van bovenstaande categoriën ingedeeld zijn vallen hieronder'
            },
          ]
        },
        {
          value: 'aantal',
          label: 'Aantal',
          children: [            
            {
              value: 'vE_OrderAantalDossiers',
              label: 'Dossiers',
              qlik: measures.aantal.dossiers,
              help: 'Het aantal dossiers, waarbij meerdere zendingen gecombineerd worden'
            },
            {
              value: 'vE_OrderAantalOrders',
              label: 'Zendingen',
              qlik: measures.aantal.orders,
              help: 'Het aantal zendingen, waarbij een zending een unieke transportopdracht is van A naar B'
            },
            {
              value: 'vE_OrderAantalRitten',
              label: 'Ritten',
              qlik: measures.aantal.ritten,
              help: 'Het aantal ritten behorend bij de geselecteerde zendingen. Hier zien we altijd het totaal. '
            },
          ]
        },
        {
          value: 'kpi',
          label: 'KPI',
          children: [
            {
              value: 'measures.kpi.goedkeur',
              label: 'OK',
              qlik: measures.kpi.goedkeur,
              help: 'Het percentage zendingen dat goedgekeurd is. Wanneer er iets misgaat met een zending wordt deze niet meegenomen in de kerngetallen.'
            },
  
            {
              value: 'measures.kpi.normmarginpercent',
              label: 'Norm Marge %',
              qlik: measures.kpi.normmarginpercent,
              help: 'De Norm Marge combineert de charter marge en de omzet/uur om deze samen te toetsen tegen de gestelde normen. Voor het eigen werk wordt gekeken hoeveel de omzet / uur afwijkt van de norm voor de omzet/uur. Dit wordt gecombineerd met de afwijking van de chartermarge ten opzichte van de norm voor de chartermarge. Dit wordt enkel uitgerekend voor goedgekeurde orders.'
            },
            {
              value: 'measures.kpi.normmarginomzet',
              label: 'Norm Marge',
              qlik: measures.kpi.normmarginomzet,
              help: 'De Norm Marge interpoleert het berekende percentage over de gehele omzet.'
            },
          ]
        },
        {
          value: 'eigenwagen',
          label: 'Eigen Wagen',
          children: [
            {
              value: 'vE_KPI_OrderOmzetPerUur',
              label: 'Omzet / Uur', 
              qlik: measures.own.omzetperuur,
              help: 'De omzet / uur is de omzet van het eigen werk minus de directe-kosten (Tol, X-dock) van het eigen werk afgezet tegen het aantal ingezette uren. Het gaat hier altijd enkel om goedgekeurde orders. '
            },
            {
              value: 'vE_KPI_Order_TripOmzetPerUur',
              label: 'Omzet / Uur - rit',
              qlik: measures.own.tripomzetperuur,
              help: 'De omzet / uur van de gehele rit(ten) waar deze order(s) ingezeten heeft/hebben. Het gaat hier altijd enkel om goedgekeurde ritten. '
            },
            {
              value: 'vE_OrderNormTurnoverHour',
              label: 'Norm Omzet / Uur',
              qlik: measures.own.normomzetperuur,
              help: 'De geconfigureerde norm op orderniveau  voor het eigen werk. De omzet per uur wordt getoetst aan deze norm. '

            },
          ]
        },
        {
          value: 'uitbesteed',
          label: 'Uitbesteed',
          children: [
            {
              value: 'vE_KPI_OrderTripOutsourced',
              label: '% Uitbesteed',
              qlik: measures.outsourced.percentage,
              help: 'Het percentage dat is uitbesteed. Indien een zending in het voorhaaltraject door een eigen wagen uitgevoerd wordt en het wegbrengen wordt uitbesteed is deze zending voor 50% uitbesteed.'
            },
            {
              value: 'vE_KPI_OrderCharterMarge',
              label: 'Derden Marge',
              qlik: measures.outsourced.marge,
              help: 'De marge op het uitbesteede werk. Dit is de omzet minus de kosten. Hierbij wordt enkel de omzet en de kosten meegenomen indien de order goedgekeurd is. '
            },
            {
              value: 'vE_OrderNormCharter',
              label: 'Norm',
              qlik: measures.outsourced.norm,
              help: 'De geconfigureerde norm op orderniveau  voor het uitbesteede werk De Derden Marge wordt getoetst aan deze norm. '
            },
          ]
        },
        {
          value: 'potency',
          label: 'Potentie',
          children: [
            {
              value: 'measures.potentie.totaal',
              label: 'Totaal',
              qlik: measures.potentie.totaal
            },
            {
              value: 'measures.potentie.commercieel',
              label: 'Commercieel',
              qlik: measures.potentie.commercieel
            },
            {
              value: 'measures.potentie.operationeel',
              label: 'Operationeel',
              qlik: measures.potentie.operationeel
            },
          ]
        },
        {
          value: 'km',
          label: 'KM',
          children: [
            {
              value: 'measures.km.totaal',
              label: 'Totaal',
              qlik: measures.km.km,
              help: 'Geeft de totale kilometers op orderniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.km.omzetperkm',
              label: 'Omzet / KM',
              qlik: measures.km.omzetperkm,
              help: 'Geeft de omzet per kilometer weer. Hierbij wordt enkel de goedgekeurde omzet afgezet tegen de goedgekeurde kilometers'
            },
            {
              value: 'measures.km.normomzetperkm',
              label: 'Norm Omzet / KM',
              qlik: measures.km.normomzetperkm,
              help: 'De geconfigureerde norm op orderniveau voor de Omzet / KM.'
            }
          ]
        },
        {
          value: 'uren',
          label: 'Uren',
          children: [
            {
              value: 'measures.uren.totaal',
              label: 'Totaal (Σ)',
              qlik: measures.uren.totaal,
              help: 'Het totaal aantal uren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.loading',
              label: 'Laden (Σ)',
              qlik: measures.uren.loading,
              help: 'Het totaal aantal laaduren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.unloading',
              label: 'Lossen (Σ)',
              qlik: measures.uren.unloading, 
              help: 'Het totaal aantal losuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

            },
            {
              value: 'measures.uren.driving',
              label: 'Rijden (Σ)',
              qlik: measures.uren.driving,
              help: 'Het totaal aantal rijuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

            },
            {
              value: 'measures.uren.other',
              label: 'Overig (Σ)',
              qlik: measures.uren.other,
              help: 'Het totaal aantal overige uren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.avg_totaal',
              label: 'Totaal (avg)',
              qlik: measures.uren.avg_totaal,
              help: 'Het gemiddeld aantal uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.avg_loading',
              label: 'Laden (avg)',
              qlik: measures.uren.avg_loading,
              help: 'Het gemiddeld aantal laaduren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.avg_unloading',
              label: 'Lossen (avg)',
              qlik: measures.uren.avg_unloading,
              help: 'Het gemiddeld aantal losuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.avg_driving',
              label: 'Rijden (avg)',
              qlik: measures.uren.avg_driving,
              help: 'Het gemiddeld aantal rijuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.avg_other',
              label: 'Overig (avg)',
              qlik: measures.uren.avg_other,
              help: 'Het gemiddeld aantal overige uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            }
          ]
        },
        {
          value: 'vergelijkperiode',
          label: 'Voorgaand jaar',
          children: [
            {
              value: 'measures.previousyear.omzet',
              label: 'Omzet',
              qlik: measures.previousyear.omzet
            },
            {
              value: 'measures.previousyear.orders',
              label: 'Order',
              qlik: measures.previousyear.orders
            },
            {
              value: 'measures.previousyear.ritten',
              label: 'Ritten',
              qlik: measures.previousyear.ritten
            },
            {
              value: 'measures.previousyear.goedkeur',
              label: 'OK',
              qlik: measures.previousyear.goedkeur
            },
  
            {
              value: 'measures.previousyear.normmarge',
              label: 'Norm Marge %',
              qlik: measures.previousyear.normmarge
            },
            {
              value: 'measures.previousyear.normmargeomzet',
              label: 'Norm Marge',
              qlik: measures.previousyear.normmargeomzet
            },
            {
              value: 'measures.previousyear.omzetperuur',
              label: 'Omzet / Uur',
              qlik: measures.previousyear.omzetperuur
            },
            {
              value: 'measures.previousyear.uitbesteed',
              label: '% Uitbesteed',
              qlik: measures.previousyear.uitbesteed
            },
            {
              value: 'measures.previousyear.chargermarge',
              label: 'Derden Marge',
              qlik: measures.previousyear.chargermarge
            },

            {
              value: 'zendinggrootte',
              label: 'Zendinggrootte',
              children: [
            {
              value: 'measures.previousyear.kg',
              label: 'Gemiddeld KG',
              qlik: measures.previousyear.kg
            },
            {
              value: 'measures.previousyear.avg_kg',
              label: 'Totaal KG',
              qlik: measures.previousyear.avg_kg
            },
            {
              value: 'measures.previousyear.avg_lm',
              label: 'Gemiddeld LM',
              qlik: measures.previousyear.avg_lm
            },
            {
              value: 'measures.previousyear.lm',
              label: 'Totaal LM',
              qlik: measures.previousyear.lm
            },
          ],
            },
           
          ],
        },
        { 
          value: 'persize',
          label: 'Per Eenheid',
          children: [
            {
              value: 'lm',
              label: 'LM',
              children: [
                {
                  value: 'measures.persize.lm',
                  label: 'Totaal',
                  qlik: measures.persize.lm,
                  help: 'Het totaal aantal laadmeters vanuit Transpas'
                },
                {
                  value: 'measures.persize.avg_lm',
                  label: 'Gemiddelde',
                  qlik: measures.persize.avg_lm,
                  help: 'Het gemiddelde aantal laadmeters per zending vanuit Transpas',
                },
              ]
            },
            {
              value: 'kg',
              label: 'KG',
              children: [
                {
                  value: 'measures.persize.kg',
                  label: 'Totaal',
                  qlik: measures.persize.kg,
                  help: 'Het totaal aantal kilo\'s vanuit Transpas'

                },
                {
                  value: 'measures.persize.avg_kg',
                  label: 'Gemiddelde',
                  qlik: measures.persize.avg_kg,
                  help: 'Het gemiddelde aantal kilo\'s vanuit Transpas'

                }
              ]
            },
            {
              value: 'zendinggrootte',
              label: 'Zendinggrootte',
              children: [
                {
                  value: 'measures.persize.shipmentsize',
                  label: 'Totaal',
                  qlik: measures.persize.shipmentsize,
                  help: 'Het totaal aantal berekende laadmeters. Waarbij M3 / KG / PP is omgerekend naar LM.'
                },
                {
                  value: 'measures.persize.omzet_shipmentsize',
                  label: 'Omzet / LM',
                  qlik: measures.persize.omzet_shipmentsize,
                  help: 'De totale omzet / gedeeld door het totale aantal berekende LM.'
                },
                {
                  value: 'measures.persize.loading_shipmentsize',
                  label: 'Laden / LM',
                  qlik: measures.persize.loading_shipmentsize,
                  help: 'Het aantal laadminuten per berekende laadmeter. Hierbij worden enkel de goedgekeurde orders meegenomen.'
                },
                {
                  value: 'measures.persize.unloading_shipmentsize',
                  label: 'Lossen / LM',
                  qlik: measures.persize.unloading_shipmentsize,
                  help: 'Het aantal losminuten per berekende laadmeter. Hierbij worden enkel de goedgekeurde orders meegenomen.'
                },
                {
                  value: 'measures.persize.time_shipmentsize',
                  label: 'Tijd / LM',
                  qlik: measures.persize.time_shipmentsize,
                  help: 'Het aantal minuten per berekende laadmeter. Hierbij worden enkel de goedgekeurde orders meegenomen.'
                },
              ]
            },
            

            //{
            //  value: 'vE_OrderShipmentSize',
            //  label: 'LM'
            //},
            
          ]
        },
      ]
    }
  ];
  